import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FinancialAccount } from '@models/financial-account';
import { Property } from '@models/property';
import { select, Store } from '@ngrx/store';
import * as fromFinancialAccounts from '@store/reducers/financial-account.reducer';
import * as fromProperties from '@store/reducers/property.reducer';
import * as fromManualAccounts from '@store/reducers/manual-account.reducer';
import { selectFinancialAccounts } from '@store/selectors/financial-account.selector';
import { selectProperties } from '@store/selectors/property.selector';
import { selectManualAccounts } from '@store/selectors/manual-account.selector';
import { AccountType } from '@models/enums';
import { Router } from '@angular/router';
import { MyfDialogService } from '@core/services/myf-dialog.service';
import { LinkAccountComponent } from '../../../accounts/link-account/link-account.component';
import { AddPropertyComponent } from '../../../properties/add-property/add-property.component';
import { AddOtherAccountComponent } from '../../../other-accounts/add-other-account/add-other-account.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  accounts: FinancialAccount[] = [];
  properties$: Observable<Property[]>;
  manualAccounts$: Observable<FinancialAccount[]>;

  accountTypes: any[] = [];
  accountTypeEnum = AccountType;

  constructor(private accountsStore: Store<fromFinancialAccounts.State>,
              private propertiesStore: Store<fromProperties.State>,
              public router: Router,
              private myfDialogService: MyfDialogService,
              private manualAccountsStore: Store<fromManualAccounts.State>) { }

  ngOnInit(): void {
    this.accountsStore.pipe(select(selectFinancialAccounts)).subscribe((financialAccounts: FinancialAccount[]) => {
      this.accounts = financialAccounts;
      this.accountTypes.length = 0;
      for (const account of financialAccounts) {
        const existing = this.accountTypes.find(x => x.label === account.AccountType);
        if (existing) {
          existing.accounts.push(account);
        } else {
          this.accountTypes.push({label: account.AccountType, accounts: [account]});
        }
      }
    });
    this.properties$ = this.propertiesStore.pipe(select(selectProperties));
    this.manualAccounts$ = this.manualAccountsStore.pipe(select(selectManualAccounts));
  }

  linkAccount(): void {
    const linkAccountModalRef = this.myfDialogService.openModal(LinkAccountComponent);
    linkAccountModalRef.cmpInstance.accountLinked.subscribe(() => {
      // this.myfDialogService.destroyModalRefExternal(linkAccountModalRef.componentRef);

    });
    linkAccountModalRef.cmpInstance.closed.subscribe(() => {
      this.myfDialogService.destroyModalRefExternal(linkAccountModalRef.componentRef);
    });
  }

  addProperty(): void {
    const addPropertyModalRef = this.myfDialogService.openModal(AddPropertyComponent);
    addPropertyModalRef.cmpInstance.propertySaved.subscribe(() => {
      this.myfDialogService.destroyModalRefExternal(addPropertyModalRef.componentRef);
    });
    addPropertyModalRef.cmpInstance.closed.subscribe(() => {
      this.myfDialogService.destroyModalRefExternal(addPropertyModalRef.componentRef);
    });
  }

  addOther(): void {
    const addOtherModalRef = this.myfDialogService.openModal(AddOtherAccountComponent);
    addOtherModalRef.cmpInstance.accountSaved.subscribe(() => {
      this.myfDialogService.destroyModalRefExternal(addOtherModalRef.componentRef);
    });
    addOtherModalRef.cmpInstance.closed.subscribe(() => {
      this.myfDialogService.destroyModalRefExternal(addOtherModalRef.componentRef);
    });
  }

}
