import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { FinancialAccount } from '@models/financial-account';
import { LoaderConfig } from '@models/loader-config';
import { Institution } from '@models/institution';
import { LoginFormResponse } from '@models/link-accounts/login-form-response';
import { LinkFinancialInstitutionRequest } from '@models/link-accounts/link-financial-institution-request';
import { LinkFinancialInstitutionResponse } from '@models/link-accounts/link-financial-institution-response';
import { LinkFinancialInstitutionMfaRequest } from '@models/link-accounts/link-financial-institution-mfa-request';
import { FinancialAccountMigrationMapping } from '@models/migration/financial-account-migration-mapping';
import { LoginForm } from '@models/link-accounts/login-form';

@Injectable()
export class AccountsService {

  loaderConfig: LoaderConfig = {
    title: 'Loading...',
    delay: 0,
    blockUI: true
  };

  constructor(private http: HttpClient) { }

  /**
   * Get list of available accounts
   */
  getAccounts(): Observable<FinancialAccount[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/FinancialAccount/accounts`;

    return this.http.get<FinancialAccount[]>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Get list of institutions for linking
   */
  getInstitutions(userId: string): Observable<Institution[]> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/institutions`;
    // const url = `${environment.API_BASE_URL}/api/2.0/financialinstitution`;

    return this.http.get<Institution[]>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Retrieve the log in form for the specified financial institution
   * @param userId - The Moneysoft ID of the user currently logged in
   * @param providerId - The “ProviderID” as specified in the financial institution
   * @param providerInstitutionId - The “ProviderInstitutionID” as specified in the financial institution
   */
  getLoginForm(userId: string, providerId: number, providerInstitutionId: number): Observable<LoginFormResponse> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/provider/${providerId}/institution/${providerInstitutionId}/loginform`;

    return this.http.get<LoginFormResponse>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Link the financial institution to the user’s Moneysoft account
   * @param userId - active user Id
   * @param financialInstitutionId - selected institution Id to link accounts
   * @param linkFinancialInstitutionRequest - payload
   */
  linkFinancialInstitution(userId: string, financialInstitutionId: number,
                           linkFinancialInstitutionRequest: LinkFinancialInstitutionRequest): Observable<LinkFinancialInstitutionResponse> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/institution/${financialInstitutionId}/allaccounts`;

    return this.http.post<LinkFinancialInstitutionResponse>(url, linkFinancialInstitutionRequest, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Link the financial institution to the user’s Moneysoft account
   * @param userId - active user Id
   * @param financialInstitutionId - selected institution Id to link accounts
   * @param institutionAccountId - institution account id
   * @param payload - payload
   */
  linkFinancialInstitutionMFA(userId: string, financialInstitutionId: number, institutionAccountId: string,
                              payload: LinkFinancialInstitutionMfaRequest): Observable<LinkFinancialInstitutionResponse> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/institution/${financialInstitutionId}/account/${institutionAccountId}/credentials`;

    return this.http.put<LinkFinancialInstitutionResponse>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * 5.5.	Updating the Log In Credentials for Accounts Already Linked
   * @param userId - active user Id
   * @param financialAccountId - selected account Id to link accounts
   * @param payload - LoginForm
   */
  updateLogInCredentials(userId: string, financialAccountId: number,
                         payload: LoginForm): Observable<LinkFinancialInstitutionResponse> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/${financialAccountId}/credentials`;

    return this.http.patch<LinkFinancialInstitutionResponse>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Unlink account
   * @param userId - user Id
   * @param financialAccountId - Id of account to unlink
   */
  refreshAccount(userId: string, financialAccountId: number): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/${financialAccountId}`;

    return this.http.patch(url, null, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Unlink account
   * @param financialAccountId - Id of account to unlink
   */
  unlinkAccount(financialAccountId: number): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/2.0/FinancialAccount/${financialAccountId}`;

    return this.http.delete(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Starting the Migration for the User
   * @param userId - The Moneysoft ID of the user currently logged in.
   */
  startMigration(userId: string): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/migrate/start`;

    return this.http.patch(url, null);
  }

  /**
   * Get institutions available for mapping
   * @param userId - The Moneysoft ID of the user currently logged in.
   * @param institutionName - pending migration institution
   */
  getMappingInstitutions(userId: string, institutionName: string): Observable<Institution[]> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/institution/${institutionName}`;

    return this.http.get<Institution[]>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Getting the Mapping from the User’s Old Accounts to their Newly Linked Accounts
   * @param userId - The Moneysoft ID of the user currently logged in
   * @param fromInstitutionId - The ID of the institution you are migrating accounts from.
   * @param toInstitutionId - The ID of the institution you are migrating accounts to.
   */
  getAccountMigrationMapping(userId: string, fromInstitutionId: number, toInstitutionId: number): Observable<FinancialAccountMigrationMapping> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/migrate/${fromInstitutionId}/mapping/${toInstitutionId}`;

    return this.http.get<FinancialAccountMigrationMapping>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Migrating the User’s accounts
   * @param userId - The Moneysoft ID of the user currently logged in
   * @param mapping - Dictionary of String and Integer key-value pairs
   */
  migrateAccounts(userId: string, mapping: any): Observable<void> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/migrate`;

    return this.http.put<void>(url, mapping, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }
}
