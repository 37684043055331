import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SplashComponent } from '@core/components/splash/splash.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { AuthGuardDefault } from '@core/guards/auth.guard.default';
import { AuthGuardDataFeed } from '@core/guards/auth.guards.data-feed';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: SplashComponent
      },
      {
        path: 'register',
        canActivate: [AuthGuardDefault],
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'accounts',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: 'properties',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/properties/properties.module').then(m => m.PropertiesModule)
      },
      {
        path: 'other-accounts',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/other-accounts/other-accounts.module').then(m => m.OtherAccountsModule)
      },
      {
        path: 'net-worth',
        canActivate: [AuthGuardDataFeed],
        loadChildren: () => import('./modules/configurator/configurator.module').then(m => m.ConfiguratorModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
