<p-dialog [style]="{width: '520px'}" [baseZIndex]="10006" [visible]="true" [draggable]="false" [modal]="true" [closable]="false" [showHeader]="true"
          [contentStyle]="{'overflow':'visible'}">
  <ng-template pTemplate="header">
    <h2 #focusable class="dialog-title" tabindex="0">{{isEditMode ? 'Edit Other' : 'Add Other'}}</h2>
  </ng-template>

  <form class="form-wrapper" [formGroup]="otherAccountForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-group">
      <label for="cmbType">Type</label>
      <p-dropdown id="cmbType" [options]="assetCategories" optionLabel="label" optionValue="value" [formControlName]="'type'"></p-dropdown>
    </div>
    <div class="form-group">
      <label for="txtNickname">Name</label>
      <input id="txtNickname" type="text" [formControlName]="'name'">
    </div>
    <div class="form-group">
      <label for="txtBalance">Current value</label>
      <div class="p-inputgroup left-side">
        <span class="p-inputgroup-addon">$</span>
        <!--<input id="txtBalance" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="1000000000" [formControlName]="'balance'" [appDecimalInput]="2">-->
        <input id="txtBalance" currencyMask class="cms-decimal-input" autocomplete="off" [formControlName]="'balance'" [options]="{ prefix: '', thousands: ',', decimal: '.', precision: 0, align: 'left' }">
      </div>
      <!--<p-inputNumber inputId="txtBalance" [formControlName]="'balance'" min="0" mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="footer-buttons">
      <p-button type="button" (click)="closeClick($event)" label="Cancel" styleClass="p-button-text"></p-button>
      <button pButton type="button" [label]="isEditMode ? 'Save' : 'Add Other'" (click)="onSubmit()" [disabled]="!otherAccountForm.valid"></button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmPopup [baseZIndex]="10007"></p-confirmPopup>
