import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { AccountsService } from '../accounts.service';
import { Institution } from '@models/institution';
import { OptionItem } from '@models/option-item';
import { ConfirmationService } from 'primeng/api';
import { InstitutionLoginComponent } from '../institution-login/institution-login.component';
import { SessionService } from '@core/services/session.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss'],
  providers: [AccountsService, ConfirmationService]
})
export class LinkAccountComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;
  @ViewChild('loginInstitution') loginInstitution: InstitutionLoginComponent;
  @Output() accountLinked = new EventEmitter();
  @Output() closed = new EventEmitter();

  institutions: Institution[] = [];
  filteredInstitutions: Institution[] = [];
  visibleFilteredInstitutions: Institution[] = [];
  selectedInstitution: Institution = null;

  sortBy = 1;
  searchParam = '';
  pageIndex = 0;

  loginFormValid = false;

  institutionLoginSuccess = false;

  sortOptions: OptionItem[] = [
    {
      label: 'Alphabetical',
      value: 0
    },
    {
      label: 'Most Popular',
      value: 1
    }
  ];

  constructor(private accountsService: AccountsService,
              private sessionService: SessionService,
              private confirmationService: ConfirmationService,
              private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.accountsService.getInstitutions(this.sessionService.user.UserID).subscribe((result: Institution[]) => {
      this.institutions = result;
      this.filter();
    });
  }

  ngAfterViewInit(): void {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        tap((text) => {
          if (this.input.nativeElement.value?.trim().length > 0) {
            this.accountsService.getMappingInstitutions(this.sessionService.user.UserID, this.input.nativeElement.value).subscribe((result) => {
              this.institutions = result;
              this.filterChanged();
            });
          } else {
            this.accountsService.getInstitutions(this.sessionService.user.UserID).subscribe((result: Institution[]) => {
              this.institutions = result;
              this.filter();
            });
          }
        })
      )
      .subscribe();
  }

  filterChanged(): void {
    this.pageIndex = 0;
    this.filter();
  }

  filter(): void {
    if (this.sortBy === 0) {
      this.filteredInstitutions = [...this.institutions].sort((a, b) => {
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
    } else {
      this.filteredInstitutions = [...this.institutions];
    }
    this.visibleFilteredInstitutions = this.filteredInstitutions.slice(this.pageIndex * 9, this.pageIndex * 9 + 9);
  }

  prev(): void {
    this.pageIndex--;
    this.filter();
  }

  next(): void {
    this.pageIndex++;
    this.filter();
  }

  link(): void {
    this.loginInstitution.login();
  }

  onLoginSuccess(): void {
    this.institutionLoginSuccess = true;
    this.accountLinked.emit();
  }

  resetLogin(): void {
    this.institutionLoginSuccess = false;
  }

  loginFormChanged(formValid: boolean): void {
    this.loginFormValid = formValid;
  }

  selectInstitution(institution: Institution): void {
    this.selectedInstitution = institution;
    this.cd.detectChanges();
  }

  closeConfirm(event): void {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure? Your changes will be lost.',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.close();
      },
      reject: () => {
      }
    });
  }

  close(): void {
    this.closed.emit();
  }

}
