import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAppResources from '../reducers/app-resources.reducer';

const appResourcesState = createFeatureSelector(fromAppResources.stateKey);

export const selectValuationProviders = createSelector(appResourcesState, (state: fromAppResources.State) => {
  return state.valuationProviders;
});
export const selectCountries = createSelector(appResourcesState, (state: fromAppResources.State) => {
  return state.countries;
});
