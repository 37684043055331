import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpParams, HttpResponse, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LoaderConfig } from '@models/loader-config';
import { LoaderService } from '@core/services/loader.service';
import { catchError, delay, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { SessionService } from '@core/services/session.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MessageService } from 'primeng/api';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private loaderService: LoaderService,
              private messageService: MessageService,
              private sessionService: SessionService,
              private router: Router) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const loaderConfig: LoaderConfig = JSON.parse(req.params.get('loaderConfig'));
    let request = req;
    let delayDuration = 0;
    if (req.url.startsWith(environment.API_FORTRESS_CLIENT_BASE_URL) || req.url.startsWith(environment.API_CLIENT_AUTH)) {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json; charset=utf-8')
        .set('Authorization', 'Bearer ' + this.sessionService.getMyFortressUserToken());
      request = req.clone({headers: httpHeaders});
    }

    if (req.url.startsWith(environment.coreLogicBaseUrl)) {
      const httpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json; charset=utf-8')
        .set('Authorization', 'Bearer ' + this.sessionService.getCoreLogicToken());
      request = req.clone({headers: httpHeaders});
    }

    if (loaderConfig) {
      delayDuration = loaderConfig.delay || 0;
      let newParams = new HttpParams({fromString: request.params.toString()});
      newParams = newParams.delete('loaderConfig');
      request = request.clone({
        params: newParams
      });

      this.loaderService.show(loaderConfig);
    }

    return next.handle(request).pipe(delay(delayDuration), tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && loaderConfig) {
        if (loaderConfig.title === 'Loading...') {
          this.loaderService.hide();
        }
      }
    }), catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          return this.refreshToken(request, next);
        } else if (err.status === 500) {
          this.loaderService.hide();
          this.messageService.add({severity: 'error', key: 'global500', sticky: true, summary: 'Error', detail: 'Unable to connect to the server, please try again later.'});
        } else {
          this.loaderService.hide();
        }

        /*if (err.status === 403) {
          this.router.navigate(['/', 'unauthorized']);
        }*/
      }
      return throwError(err);
    }));
  }

  refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);

      return this.sessionService.refreshAccessToken().pipe(
        switchMap((refreshResponse: any) => {
          if (refreshResponse.response) {
            this.sessionService.setAccessToken(refreshResponse.response.accessToken);
            this.sessionService.setRefreshToken(refreshResponse.response.refreshToken);
            localStorage.setItem('expires_in', refreshResponse.response.expiresIn.toString());
            localStorage.setItem('token_type', refreshResponse.response.tokenType);
            const httpHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json; charset=utf-8')
              .set('Authorization', 'Bearer ' + this.sessionService.getAccessToken());
            const authReq = req.clone({ headers: httpHeaders });

            const loaderConfig = new LoaderConfig();
            loaderConfig.title = 'Loading...';
            loaderConfig.blockUI = true;
            loaderConfig.delay = 0;
            this.loaderService.show(loaderConfig);

            return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                this.loaderService.hide();
              }
            }), catchError((err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                  return this.refreshToken(authReq, next);
                } else if (err.status === 500) {
                  this.loaderService.hide();
                  this.messageService.add({severity: 'error', key: 'global500', sticky: true, summary: 'Error', detail: 'Unable to connect to the server, please try again later.'});
                } else {
                  this.loaderService.hide();
                }
              }
              return throwError(err);
            }));
          } else {
            return this.sessionService.impersonate().pipe(
              switchMap((authResponse: any) => {
                if (authResponse.response) {
                  this.sessionService.setAccessToken(authResponse.response.accessToken);
                  this.sessionService.setRefreshToken(authResponse.response.refreshToken);
                  localStorage.setItem('expires_in', authResponse.response.expiresIn.toString());
                  localStorage.setItem('token_type', authResponse.response.tokenType);
                  const httpHeaders = new HttpHeaders()
                    .set('Content-Type', 'application/json; charset=utf-8')
                    .set('Authorization', 'Bearer ' + this.sessionService.getAccessToken());
                  const authReq = req.clone({ headers: httpHeaders });

                  const loaderConfig = new LoaderConfig();
                  loaderConfig.title = 'Loading...';
                  loaderConfig.blockUI = true;
                  loaderConfig.delay = 0;
                  this.loaderService.show(loaderConfig);

                  return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                      this.loaderService.hide();
                    }
                  }), catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                      if (err.status === 401) {
                        return this.refreshToken(authReq, next);
                      } else if (err.status === 500) {
                        this.loaderService.hide();
                        this.messageService.add({severity: 'error', key: 'global500', sticky: true, summary: 'Error', detail: 'Unable to connect to the server, please try again later.'});
                      } else {
                        this.loaderService.hide();
                      }
                    }
                    return throwError(err);
                  }));
                }

                // If we don't get a new token, we are in trouble so logout.
                return this.logoutUser();
              }),
              catchError(() => {
                // If there is an exception calling 'refreshToken', bad news so logout.
                return this.logoutUser();
              }),
              finalize(() => {
                this.isRefreshingToken = false;
              }));
          }

          // If we don't get a new token, we are in trouble so logout.
        }),
        catchError(() => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      this.isRefreshingToken = false;
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          return next.handle(req);
        }));
    }
  }

  logoutUser(): Observable<any> {
    // this.router.navigate(['/register']);
    return throwError('');
  }
}
