import { Injectable } from '@angular/core';
import { LoaderConfig } from '@models/loader-config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ValuationProvider } from '@models/valuation-provider';
import { map } from 'rxjs/operators';
import { LoadCountries, LoadValuationProviders } from '@store/actions/app-resources.actions';
import { Store } from '@ngrx/store';
import * as fromAppResources from '@store/reducers/app-resources.reducer';
import { Country } from '@models/country';
import { PropertyAddress } from '@models/property-address';
import { Property } from '@models/property';
import { CreateProperty } from '@models/property/create-property';

@Injectable()
export class PropertiesService {
  loaderConfig: LoaderConfig = {
    title: 'Loading...',
    delay: 0,
    blockUI: true
  };

  constructor(private http: HttpClient,
              private store: Store<fromAppResources.State>) {

  }

  /**
   * Create property
   * @param payload - body
   */
  createProperty(payload: CreateProperty): Observable<Property> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/Save`;

    return this.http.post<Property>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Update property
   * @param payload - body
   */
  updateProperty(payload: Property): Observable<Property> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/Save`;

    return this.http.put<Property>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Delete property
   * @param property - Property object to unlink
   */
  deleteProperty(property: Property): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property`;

    const options = {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig)),
      body: [property],
    };

    return this.http.delete(url, options);
  }

  /**
   * Get list og valuation providers
   */
  getValuationProviders(): Observable<ValuationProvider[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/Providers`;

    return this.http.get<ValuationProvider[]>(url).pipe(map((valuationProviders: ValuationProvider[]) => {
      this.store.dispatch(LoadValuationProviders({payload: valuationProviders}));
      return valuationProviders;
    }));
  }

  /**
   * Get list og valuation providers
   */
  getCountries(providerId: number): Observable<Country[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/States/${providerId}`;

    return this.http.get<Country[]>(url).pipe(map((countries: Country[]) => {
      this.store.dispatch(LoadCountries({payload: countries}));
      return countries;
    }));
  }

  /**
   * Get list of addresses
   */
  getPropertyAddresses(providerId: number, street: string, suburb: string, state: string, code: string): Observable<PropertyAddress[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/Search/${providerId}?search.street=${street}&search.suburb=${suburb}&search.state=${state}&search.code=${code}`;

    return this.http.get<PropertyAddress[]>(url);
  }

  /**
   * Estimate property
   */
  estimateProperty(providerId: number, addressId: string): Observable<PropertyAddress> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property/Estimate/${providerId}?id=${addressId}`;

    return this.http.get<PropertyAddress>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }
}
