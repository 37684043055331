import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DialogModule } from 'primeng/dialog';
import { SplashComponent } from './components/splash/splash.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    LoaderComponent,
    SplashComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    LoaderComponent,
    SplashComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    RouterModule,
    SharedModule
  ],
  providers: [
    MessageService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Core Module has already been loaded.');
    }
  }
}
