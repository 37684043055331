import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '@core/services/session.service';
import * as fromDataFeedReducer from '@store/reducers/data-feed.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardDataFeed implements CanActivate {

  constructor(private session: SessionService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.session.setParamData(route.queryParams)
    return this.session.authenticateDataFeed(state.url);
  }
}
