import { Component, OnInit } from '@angular/core';
import { User } from '@models/user';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromUser from '@store/reducers/user.reducer';
import { selectUser } from '@store/selectors/user.selector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  activeUser$: Observable<User>;

  constructor(private store: Store<fromUser.State>) { }

  ngOnInit(): void {
    this.activeUser$ = this.store.pipe(select(selectUser));
  }

}
