import { FinancialAccount } from '@models/financial-account';
import { Action, createReducer, on } from '@ngrx/store';
import {
  LinkFinancialInstitution,
  LoadFinancialAccounts,
  RefreshFinancialAccountFailed,
  UnlinkFinancialAccount
} from '../actions/financial-account.actions';

export const stateKey = 'financialAccountsState';

export interface State {
  financialAccounts: FinancialAccount[];
  refreshFailedAccountIds: number[];
}
export const initialState: State = {
  financialAccounts: [],
  refreshFailedAccountIds: []
};

const financialAccountsReducer = createReducer(
  initialState,
  on(LoadFinancialAccounts, (state, { payload }) => ({
    financialAccounts: [...payload],
    refreshFailedAccountIds: state.refreshFailedAccountIds
  })),
  on(RefreshFinancialAccountFailed, (state, { payload }) => ({
    financialAccounts: state.financialAccounts,
    refreshFailedAccountIds: payload
  })),
  on(UnlinkFinancialAccount, (state, { payload }) => ({
    financialAccounts: state.financialAccounts.filter(x => x.FinancialAccountID !== payload),
    refreshFailedAccountIds: state.refreshFailedAccountIds
  })),
  on(LinkFinancialInstitution, (state, { payload }) => ({
    financialAccounts: [...payload],
    refreshFailedAccountIds: state.refreshFailedAccountIds
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return financialAccountsReducer(state, action);
}
