import { FinancialAccount } from '@models/financial-account';
import { Action, createReducer, on } from '@ngrx/store';
import { AddManualAccount, LoadManualAccounts, UnlinkManualAccount, UpdateManualAccount } from '../actions/manual-account.actions';

export const stateKey = 'manualAccountsState';

export interface State {
  manualAccounts: FinancialAccount[];
}
export const initialState: State = {
  manualAccounts: []
};

const manualAccountsReducer = createReducer(
  initialState,
  on(LoadManualAccounts, (state, { payload }) => ({
    manualAccounts: [...payload]
  })),
  on(AddManualAccount, (state, { payload }) => ({
    manualAccounts: [...state.manualAccounts, payload]
  })),
  on(UpdateManualAccount, (state, { payload }) => ({
    manualAccounts: state.manualAccounts.map(item => {
      return item.FinancialAccountID === payload.FinancialAccountID ? Object.assign({}, item, payload) : item;
    })
  })),
  on(UnlinkManualAccount, (state, { payload }) => ({
    manualAccounts: state.manualAccounts.filter(x => x.FinancialAccountID !== payload)
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return manualAccountsReducer(state, action);
}
