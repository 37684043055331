import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDecimal'
})
export class TwoDecimalPipe implements PipeTransform {
  transform(amount: Number) {
    const value = amount.toString().match(/^-?\d+(?:\.\d{0,2})?/); // remove decimals after two
    const demilaWithValue = (+value).toFixed(2); // add decimals for non decimals
    return demilaWithValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); // thousand seperator
  }
}
