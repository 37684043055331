import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { StoreModule } from '@ngrx/store';
import * as fromAppResources from './store/reducers/app-resources.reducer';
import * as fromFinancialAccounts from './store/reducers/financial-account.reducer';
import * as fromProperties from './store/reducers/property.reducer';
import * as fromManualAccounts from './store/reducers/manual-account.reducer';
import * as fromUser from './store/reducers/user.reducer';
import * as fromMigration from './store/reducers/migration.reducer';
import * as fromDataFeedAccounts from './store/reducers/data-feed.reducer';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';

export function getAccessToken(): string {
  return localStorage.getItem('access_token');
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  overlayColor: 'rgba(255,255,255,.66)',
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CoreModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getAccessToken,
        allowedDomains: environment.JWT_DOMAINS
      }
    }),
    StoreModule.forRoot({
      appResourcesState: fromAppResources.reducer,
      financialAccountsState: fromFinancialAccounts.reducer,
      propertiesState: fromProperties.reducer,
      manualAccountsState: fromManualAccounts.reducer,
      userState: fromUser.reducer,
      migrationState: fromMigration.reducer,
      dataFeedAccountsState: fromDataFeedAccounts.reducer,
    }),
    ToastModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
