import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { User } from '@models/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../../environments/environment';
import { LoaderConfig } from '@models/loader-config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromUser from '@store/reducers/user.reducer';
import { LoadUser } from '@store/actions/user.actions';
import { CoreService } from '@core/services/core.service';
import { LoadFinancialAccounts } from '@store/actions/financial-account.actions';
import { LoadProperties } from '@store/actions/property.actions';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromFinancialAccounts from '@store/reducers/financial-account.reducer';
import * as fromProperties from '@store/reducers/property.reducer';
import * as fromManualAccounts from '@store/reducers/manual-account.reducer';
import * as fromMigrations from '@store/reducers/migration.reducer';
import { LoadManualAccounts } from '@store/actions/manual-account.actions';
import { LoadPendingMigrations } from '@store/actions/migration.actions';
import { AccountStatus, AssetCategory } from '@models/enums';

import * as fromDataFeedReducer from '@store/reducers/data-feed.reducer';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  /**
   * Active user
   */
  public currentUser$ = new BehaviorSubject<User>(new User());
  /**
   * User.
   */
  user: User;

  private moneysoftUserId: string;
  private moneysoftUserChanged = false;
  /**
   * Access token.
   */
  private token: string = null;
  /**
   * Refresh token used to get new access token after access token expires
   * @private
   */
  private refreshToken: string = null;

  /**
   * Indicated if upgrade notification is shown to the user for the current session
   */
  showUpgradeNotification = true;

  constructor(private http: HttpClient,
    private coreService: CoreService,
    private router: Router,
    private accountsStore: Store<fromFinancialAccounts.State>,
    private propertiesStore: Store<fromProperties.State>,
    private migrationStore: Store<fromMigrations.State>,
    private manualAccountsStore: Store<fromManualAccounts.State>,
    private store: Store<fromUser.State>,
    private cookieService: CookieService,
    private jwtHelperService: JwtHelperService,
  ) {

    const token: string = this.jwtHelperService.tokenGetter();
    if (token !== null) {
      this.token = token;
    }
  }

  /**
   * Set user data
   */
  private setUser(userData: User): void {
    this.user = userData;
    this.currentUser$.next(this.user);
    this.store.dispatch(LoadUser({ payload: this.user }));

    this.loadResources();
  }

  /**
   * Set access token.
   * @param token
   */
  setAccessToken(token): void {
    this.token = token;
    localStorage.setItem('access_token', token);
  }

  /**
   * Set refresh token.
   * @param token
   */
  setRefreshToken(token): void {
    this.refreshToken = token;
    localStorage.setItem('refresh_token', token);
  }

  setMoneysoftUserId(userId: string): void {
    this.moneysoftUserChanged = userId !== this.moneysoftUserId;

    this.moneysoftUserId = userId;
    localStorage.setItem('moneysoft_user_id', userId);
  }

  /**
   * Invalidate access token
   */
  private invalidateAccessToken(): void {
    this.token = null;
    localStorage.removeItem('access_token');
  }

  /**
   * Invalidate refresh token
   */
  private invalidateRefreshToken(): void {
    this.refreshToken = null;
    localStorage.removeItem('refresh_token');
  }

  /**
   * Invalidate refresh token
   */
  private invalidateMoneysoftUserId(): void {
    this.moneysoftUserId = null;
    localStorage.removeItem('moneysoft_user_id');
  }

  /**
   * Validate access token
   * @return {boolean}
   */
  validateAccessToken(): boolean {
    return this.getAccessToken() !== null && this.getAccessToken() !== undefined;
  }

  getMyFortressUserToken(): string {
    return this.cookieService.get('currentUserToken');
  }

  getCoreLogicToken() {
    return this.cookieService.get('CoreLogicToken')
  }

  setCoreLogicToken(token: string) {
    this.cookieService.set('CoreLogicToken', token)
  }

  getMyFortressUserId(): string {
    return this.cookieService.get('CurrentUserId');
  }

  /**
   * Get access token.
   * @returns {string}
   */
  getAccessToken(): string {
    return this.token;
  }

  /**
   * Get refresh token.
   * @returns {string}
   */
  getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  getMoneysoftUserId(): string {
    return localStorage.getItem('moneysoft_user_id');
  }

  /**
   * Get token expiration date
   * @return {Date}
   */
  getTokenExpirationDate(): Date {
    return this.jwtHelperService.getTokenExpirationDate(this.token);
  }

  /**
   * Logout the user.
   */
  logout(redirectUrl: string): void {
    this.user = null;
    this.invalidateAccessToken();
    this.invalidateRefreshToken();
    this.currentUser$.next(this.user);
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.router.navigate(['/register'], { queryParams: { returnUrl: redirectUrl, xeppoCode: userData?.xeppoCode, first_name: userData?.first_name, last_name: userData?.last_name, isMigrated: userData?.isMigrated } });
  }

  loadUser(): void {
    this.coreService.getUser().subscribe((user) => {
      this.setUser(user);
      this.loadResources();
    });
  }

  /**
   * Get the user
   * @returns {Observable<boolean>}
   */
  authenticateUser(redirectUrl: string): Observable<boolean> {
    const canActivate = new ReplaySubject<boolean>();
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.coreService.getMigrateStatus(userData?.xeppoCode, this.cookieService.get('companyCode')).subscribe((res: any) => {
      userData['isMigrated'] = res.response.toString()
      localStorage.setItem('userData', JSON.stringify(userData));
      const migrated = JSON.parse(localStorage.getItem('userData'))?.isMigrated === 'true'
      if (!migrated) {
        if (this.moneysoftUserChanged) {
          this.moneysoftUserChanged = false;
          this.coreService.impersonate(this.getMoneysoftUserId()).subscribe((authResponse) => {
            if (authResponse.response) {
              this.setAccessToken(authResponse.response.accessToken);
              this.setRefreshToken(authResponse.response.refreshToken);
              localStorage.setItem('expires_in', authResponse.response.expiresIn.toString());
              localStorage.setItem('token_type', authResponse.response.tokenType);

              this.coreService.getUser().subscribe((userData: User) => {
                this.setUser(userData);
                canActivate.next(true);
              }, () => {
                this.logout(redirectUrl);
                canActivate.next(false);
              });
            } else {
              this.logout(redirectUrl);
              canActivate.next(false);
            }
          }, () => {
            this.logout(redirectUrl);
            canActivate.next(false);
          });
        } else {
          this.coreService.getUser().subscribe((userData: User) => {
            localStorage.setItem('redirect_uri', redirectUrl);
            this.setUser(userData);
            canActivate.next(true);
          }, () => {
            this.logout(redirectUrl);
            canActivate.next(false);
          });
        }
      } else {
        canActivate.next(false);
        this.router.navigate(['/net-worth'], { queryParams: { xeppoCode: userData?.xeppoCode, first_name: userData?.first_name, last_name: userData?.last_name, isMigrated: userData?.isMigrated } })

      }
    })

    return canActivate;
  }

  authenticateDataFeed(redirectUrl: string): Observable<boolean> {
    const canActivate = new ReplaySubject<boolean>();
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.coreService.getMigrateStatus(userData?.xeppoCode, this.cookieService.get('companyCode')).subscribe((res: any) => {
      userData['isMigrated'] = res.response.toString()
      localStorage.setItem('userData', JSON.stringify(userData));
      const migrated = JSON.parse(localStorage.getItem('userData'))?.isMigrated === 'true'
      canActivate.next(migrated);
      if (!migrated) {
        this.logout(redirectUrl);
      }
    })
    return canActivate;
  }

  /**
   * Check if token has expired
   * @return {boolean}
   */
  isTokenExpired(): boolean {
    return this.jwtHelperService.isTokenExpired(this.token);
  }

  /**
   * Get user from JWT.
   * @returns {Observable<Object>}
   */
  private getUser(): Observable<User> {
    let jwt;
    try {
      jwt = this.jwtHelperService.decodeToken(this.token);
    } catch (e) {
      jwt = {
        userId: -1
      };
    }
    const url = `${environment.API_BASE_URL}/users/${jwt.userId}`;
    const loaderConfig: LoaderConfig = {
      title: 'Loading...',
      delay: 500
    };

    return this.http.get<User>(url, {
      params: new HttpParams()
        .set('loaderConfig', JSON.stringify(loaderConfig))
    });
  }

  loginAdviser(): Observable<any> {
    const url = `${environment.API_FORTRESS_CLIENT_BASE_URL}/api/Client/MsAdviserToken`;

    const loaderConfig: LoaderConfig = {
      title: 'Loading...',
      delay: 500
    };

    return this.http.get(url, {
      params: new HttpParams()
        .set('loaderConfig', JSON.stringify(loaderConfig))
    });
  }

  impersonate(): Observable<any> {
    return this.coreService.impersonate(this.getMoneysoftUserId());
  }

  refreshAccessToken(): Observable<any> {
    const url = `${environment.API_FORTRESS_CLIENT_BASE_URL}/api/Client/MsRefreshToken?refresh_token=${this.getRefreshToken()}`;

    const loaderConfig: LoaderConfig = {
      title: 'Refreshing token...',
      delay: 0,
      blockUI: true
    };

    return this.http.get(url, {
      params: new HttpParams()
        .set('loaderConfig', JSON.stringify(loaderConfig))
    });
  }

  private loadResources(): void {
    const accounts$ = this.coreService.getAccounts().pipe(
      catchError(() => of([])),
    );
    const properties$ = this.coreService.getProperties().pipe(
      catchError(() => of([])),
    );
    const pendingMigrations$ = this.coreService.getPendingMigrations(this.user.UserID).pipe(
      catchError(() => of([])),
    );

    forkJoin([accounts$, properties$, pendingMigrations$]).subscribe((data) => {

      if (data[0] !== null && data[0] !== undefined) {
        this.accountsStore.dispatch(LoadFinancialAccounts({
          payload: data[0].filter(x => x.AssetCategory !== AssetCategory.PROPERTY &&
            !x.ManualAccount && x.AccountStatus !== AccountStatus.PENDING_DELETE && x.AccountStatus !== AccountStatus.DELETED)
        }));
        this.manualAccountsStore.dispatch(LoadManualAccounts({ payload: data[0].filter(x => x.ManualAccount) }));
      }
      if (data[1] !== null && data[1] !== undefined) {
        this.propertiesStore.dispatch(LoadProperties({ payload: data[1] }));
      }
      if (data[2] !== null && data[2] !== undefined) {
        this.migrationStore.dispatch(LoadPendingMigrations({ payload: data[2] }));
      }

      const redirectUri = localStorage.getItem('redirect_uri');
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (redirectUri) {
        localStorage.removeItem('redirect_uri');
        if(redirectUri !== '' && redirectUri !== '/') {
          this.router.navigateByUrl(redirectUri);
        } else {
          this.router.navigate(['accounts'], { queryParams: { xeppoCode: userData?.xeppoCode, first_name: userData?.first_name, last_name: userData?.last_name, isMigrated: userData?.isMigrated } });
        }
      } else {
        this.router.navigate(['accounts'], { queryParams: { xeppoCode: userData?.xeppoCode, first_name: userData?.first_name, last_name: userData?.last_name, isMigrated: userData?.isMigrated } });
      }
    });
  }

  setParamData(params) {
    if (params && Object.keys(params).length > 0) {
      if (params.hasOwnProperty('xeppoCode') && params.hasOwnProperty('first_name') && params.hasOwnProperty('last_name') && params.hasOwnProperty('isMigrated')) {
        const data = {
          first_name: params.first_name,
          last_name: params.last_name,
          xeppoCode: params.xeppoCode,
          isMigrated: params.isMigrated
        }
        this.coreService.getMigrateStatus(params.xeppoCode, this.cookieService.get('companyCode')).subscribe((res: any) => {
          Object.assign(data, { isMigrated: res.response.toString() })
        })
        localStorage.setItem('userData', JSON.stringify(data));
      }
    }
  }
}
