import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderConfig } from '@models/loader-config';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // private modalCount = 0;
  private localSubject: Subject<LoaderConfig> = new Subject<LoaderConfig>();

  public get subject(): Subject<LoaderConfig> {
    return this.localSubject;
  }

  public show(loaderConfig: LoaderConfig): void {
    // this.modalCount++;
    this.localSubject.next(loaderConfig);
  }

  public hide(): void {
    /*if (this.modalCount > 0) {
      this.modalCount--;
    }

    if (this.modalCount === 0) {
      this.localSubject.next();
    }*/
    this.localSubject.next();
  }
}
