import { Pipe, PipeTransform } from '@angular/core';
import { AssetCategory } from '@models/enums';

@Pipe({
  name: 'assetCategoryImage'
})
export class AssetCategoryImagePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case AssetCategory.BANK: {
        return 'assets/images/ic-bank.svg';
      }
      case AssetCategory.PROPERTY: {
        return 'assets/images/ic-property-blue.svg';
      }
      case AssetCategory.VEHICLE: {
        return 'assets/images/ic-vehicle.svg';
      }
      case AssetCategory.INVESTMENT: {
        return 'assets/images/ic-investment.svg';
      }
      case AssetCategory.CREDIT_CARD: {
        return 'assets/images/ic-credit-card.svg';
      }
      case AssetCategory.LOAN: {
        return 'assets/images/ic-loan.svg';
      }
      case AssetCategory.OTHER_ASSET: {
        return 'assets/images/ic-other-blue.svg';
      }
      case AssetCategory.LOYALTY: {
        return 'assets/images/ic-bank.svg';
      }
      case AssetCategory.OTHER_LIABILITY: {
        return 'assets/images/ic-other-blue.svg';
      }
      case AssetCategory.INSURANCE: {
        return 'assets/images/ic-bank.svg';
      }
      case AssetCategory.REWARD: {
        return 'assets/images/ic-bank.svg';
      }
      case AssetCategory.BILL: {
        return 'assets/images/ic-bank.svg';
      }
    }
  }

}
