<div class="login-wrapper">
  <div class="form-panel" [ngClass]="{'fill': simpleView}">
    <app-institution-info *ngIf="!simpleView" [institution]="institution"></app-institution-info>
    <form *ngIf="formCreated && !loginFinishedSuccessfully" class="form-wrapper" [formGroup]="loginForm" (ngSubmit)="submit()" novalidate>
      <div class="form-group" *ngFor="let field of formFields">
        <label>{{field.DisplayName}}</label>
        <ng-container [ngSwitch]="field.FieldType">
          <ng-container *ngSwitchCase="fieldTypeEnum.TEXT">
            <input type="text" [formControlName]="field.Name">
          </ng-container>
          <ng-container *ngSwitchCase="fieldTypeEnum.PASSWORD">
            <div class="group-wrapper">
              <input [type]="field.InputType" [formControlName]="field.Name">
              <button class="btn-toggle-password" type="button" (click)="togglePassword(field)">
                <i [class]="field.InputType === 'password' ? 'pi pi-eye-slash' : 'pi pi-eye'" aria-hidden="true"></i>
              </button>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="fieldTypeEnum.OPTION">
            <p-dropdown [options]="field.ValidValues" optionLabel="DisplayText" optionValue="OptionValue" [formControlName]="field.Name"></p-dropdown>
          </ng-container>
        </ng-container>
      </div>
      <div class="button-wrapper" *ngIf="showLoginButton">
        <button pButton type="button" label="Login" (click)="submit()" [disabled]="loginForm.invalid"></button>
      </div>
    </form>
    <div class="login-summary" *ngIf="loginFinishedSuccessfully">
      <ng-container *ngIf="!institutionAlreadyLinked">
        <img src="assets/images/ic-success-m.svg" height="56px" width="56px" alt="Login success">
        <p *ngIf="!isUpdatingLoginCredentials">You have successfully logged in.</p>
        <p *ngIf="isUpdatingLoginCredentials">Credentials are successfully updated.</p>
      </ng-container>
      <ng-container *ngIf="institutionAlreadyLinked">
        <img src="assets/images/ic-warning-m.svg" height="56px" width="56px" alt="Login success">
        <p>You have successfully logged in, but you’ve already added all accounts from this institution.</p>
        <p-button type="button" (click)="resetClick()" label="Login with other Account details >" styleClass="p-button-text"></p-button>
      </ng-container>
    </div>
  </div>
  <div class="disclaimer" *ngIf="!simpleView">
    <h3>Your Security is Paramount!</h3>

    <p>We use PCI Level 1 Standards, which is the highest level of security. This is the same security used by the Major Banks of Australia.
    </p>
    <p>
      We only establish a link to your account to deliver transaction data. Your account details & logins are not stored by our platform.
    </p>
    <p>
      This is a read-only access platform. You can not transact or move money using the platform.
    </p>
    <img src="assets/images/norton.png" height="44" alt="Norton Secured">
  </div>
</div>
<p-toast position="bottom-center"></p-toast>
