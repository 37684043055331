// test
export const environment = {
  production: false,
  API_BASE_URL: 'https://api.sandbox.moneysoft.com.au',
  API_FORTRESS_CLIENT_BASE_URL: 'https://api.client.fortress.xigenix.com',
  API_CLIENT_AUTH: 'https://api.clientauth.fortress.xigenix.com',
  JWT_DOMAINS: ['api.sandbox.moneysoft.com.au'],
  INSTITUTION_LOGO_BASE_URL: 'https://pfm.sandbox.moneysoft.com.au/assets/img/institutions/',
  STAFF_PORTAL_URL: 'https://staffportal.fortress.xigenix.com',
  //live development fastlink details
  fastLinkUrl: 'https://fl4.preprod.yodlee.com.au/authenticate/ANZDevexPreProd-158/fastlink?channelAppName=anzdevexpreprod',
  apiVersion: '1.1',
   coreLogicBaseUrl: 'https://api-uat.corelogic.asia',

  //test fastlink details TODO:FIXME:
  // fastLinkUrl: 'https://fl4.sandbox.yodlee.com.au/authenticate/anzdevexsandbox/fastlink/',
  // apiVersion: '1.1',
  // loginName: 'sbMem612c6191be6701',
  // loginName: 'sbMem612c6191be6702',
  // loginName: 'sbMem612c6191be6703',
  // loginName: 'sbMem612c6191be6704',
  // loginName: 'sbMem612c6191be6705',
  // API_YODLEE_BASE_URL: 'https://sandbox.api.yodlee.com.au/ysl',
  // clientId: 'QGfdLgU2EIh20Z0ij5YxDQ3tNx4uHvPa',
  // secret: 'eBzfvkfBHPTqNDMc',
};
