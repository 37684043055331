import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { SessionService } from '@core/services/session.service';
import { select, Store } from '@ngrx/store';
import { isMigrated, userData } from '@store/selectors/data-feed.selector';
import * as fromDataFeedReducer from '@store/reducers/data-feed.reducer';
import { CoreService } from '@core/services/core.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardDefault implements CanActivate {

  constructor(private session: SessionService,
              private router: Router,
              private coreService: CoreService,
              private cookieService: CookieService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivate = new ReplaySubject<boolean>();
    this.session.setParamData(route.queryParams);
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.coreService.getMigrateStatus(userData?.xeppoCode, this.cookieService.get('companyCode')).subscribe((res: any) => {
      userData['isMigrated'] = res.response.toString();
      localStorage.setItem('userData', JSON.stringify(userData));
      const migrated = JSON.parse(localStorage.getItem('userData'))?.isMigrated === 'true'
      canActivate.next(!migrated);
      if(migrated) {
        this.router.navigate(['/net-worth'], {queryParams: {xeppoCode: userData?.xeppoCode, first_name: userData?.first_name, last_name: userData?.last_name, isMigrated: userData?.isMigrated}})
      }
    })
    return canActivate;
  }
}
