import { Pipe, PipeTransform } from '@angular/core';
import { AccountType } from '@models/enums';

@Pipe({
  name: 'accountTypeName'
})
export class AccountTypeNamePipe implements PipeTransform {

  transform(value: number, plural = false): string {
    switch (value) {
      case AccountType.Bank: {
        return plural ? 'Bank accounts' : 'Bank account';
      }
      case AccountType.CreditCard: {
        return plural ? 'Credit cards' : 'Credit card';
      }
      case AccountType.Loan: {
        return plural ? 'Loans' : 'Loan';
      }
      case AccountType.Investment: {
        return plural ? 'Investments' : 'Investment';
      }
      case AccountType.Mortgage: {
        return plural ? 'Mortgages' : 'Mortgage';
      }
      case AccountType.Asset: {
        return plural ? 'Assets' : 'Asset';
      }
      case AccountType.Liability: {
        return plural ? 'Liabilities' : 'Liability';
      }
      case AccountType.Bills: {
        return 'Bills';
      }
      case AccountType.Insurance: {
        return plural ? 'Insurances' : 'Insurance';
      }
      case AccountType.Loyalty: {
        return plural ? 'Loyalties' : 'Loyalty';
      }
      case AccountType.Property: {
        return plural ? 'Properties' : 'Property';
      }
      default: {
        return 'Unknown';
      }
    }
  }

}
