import { Action, createReducer, on } from '@ngrx/store';
import { ValuationProvider } from '@models/valuation-provider';
import { LoadCountries, LoadValuationProviders } from '@store/actions/app-resources.actions';
import { Country } from '@models/country';

export const stateKey = 'appResourcesState';

export interface State {
  valuationProviders: ValuationProvider[];
  countries: Country[];
}
export const initialState: State = {
  valuationProviders: [],
  countries: []
};

const appResourcesReducer = createReducer(
  initialState,
  on(LoadValuationProviders, (state, { payload }) => ({
    ...state,
    valuationProviders: [...payload]
  })),
  on(LoadCountries, (state, { payload }) => ({
    ...state,
    countries: [...payload]
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return appResourcesReducer(state, action);
}
