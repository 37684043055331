export class CreateProperty {
  PropertyID: number;
  ProviderID: number;
  DisplayName: string;
  Address: string;
  UseEstimate: boolean;
  EstimatedValue: number;
  ReportedValue: number;
  PropertyType: number;
  ProviderPropertyID: string;
  Street: string;
  Suburb: string;
  State: string;
  Code: string;
  Usage: number;
  Bedrooms: number;
  Bathrooms: number;
  Carparks: number;
  Landarea: number;
}
