import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFinancialAccounts from '../reducers/financial-account.reducer';

const financialAccountsState = createFeatureSelector(fromFinancialAccounts.stateKey);

export const selectFinancialAccounts = createSelector(financialAccountsState, (state: fromFinancialAccounts.State) => {
  return state.financialAccounts;
});

export const selectRefreshFailedAccountIds = createSelector(financialAccountsState, (state: fromFinancialAccounts.State) => {
  return state.refreshFailedAccountIds;
});
