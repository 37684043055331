export class User {
  UserID: string;
  PlatformId: number;
  UserName: string;
  AdviserID: string;
  Firstname: string;
  Lastname: string;
  DateCreated: Date;
  Email: string;
}
