import { ChangeDetectorRef, EventEmitter, Component, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Institution } from '@models/institution';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FinancialField, LoginFormResponse } from '@models/link-accounts/login-form-response';
import { FieldType } from '@models/enums';
import { AccountsService } from '../accounts.service';
import { LinkFinancialInstitutionRequest } from '@models/link-accounts/link-financial-institution-request';
import { LinkFinancialInstitutionResponse } from '@models/link-accounts/link-financial-institution-response';
import { select, Store } from '@ngrx/store';
import * as fromFinancialAccounts from '@store/reducers/financial-account.reducer';
import { LinkFinancialInstitution } from '@store/actions/financial-account.actions';
import { LoginForm } from '@models/link-accounts/login-form';
import { LinkFinancialInstitutionMfaRequest } from '@models/link-accounts/link-financial-institution-mfa-request';
import { InstitutionAccount } from '@models/link-accounts/institution-account';
import { FinancialAccount } from '@models/financial-account';
import { Subscription } from 'rxjs';
import { selectFinancialAccounts } from '@store/selectors/financial-account.selector';
import { SessionService } from '@core/services/session.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-institution-login',
  templateUrl: './institution-login.component.html',
  styleUrls: ['./institution-login.component.scss'],
  providers: [MessageService]
})
export class InstitutionLoginComponent implements OnInit, OnDestroy {
  @Input() institution: Institution;
  @Input() account: FinancialAccount;
  @Input() simpleView = false;
  @Input() showLoginButton = false;
  @Input() isUpdatingLoginCredentials = false;
  @Output() loginSuccess = new EventEmitter<FinancialAccount[]>();
  @Output() loginSuccessWithNoAccounts = new EventEmitter();
  @Output() updateCredentialsSuccess = new EventEmitter<FinancialAccount[]>();
  @Output() loginFailed = new EventEmitter();
  @Output() resetClicked = new EventEmitter();
  @Output() formChanged = new EventEmitter<boolean>();

  loginForm: FormGroup;
  formFields: FinancialField[] = [];
  formId: number;
  fieldTypeEnum = FieldType;
  isMFA = false;
  institutionAccount: InstitutionAccount;

  subs: Subscription = new Subscription();
  linkedAccounts: FinancialAccount[] = [];

  loginFormResponse: LoginFormResponse;
  formCreated = false;
  loginFinishedSuccessfully = false;
  institutionAlreadyLinked = false;

  constructor(private fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private messageService: MessageService,
              private sessionService: SessionService,
              private store: Store<fromFinancialAccounts.State>,
              private accountsService: AccountsService) { }

  ngOnInit(): void {
    this.getLoginForm();

    this.subs.add(
      this.store.pipe(select(selectFinancialAccounts)).subscribe((result: FinancialAccount[]) => {
        this.linkedAccounts = [...result];
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getLoginForm(): void {
    this.accountsService.getLoginForm(this.sessionService.user.UserID, this.institution.ProviderID,
      this.institution.ProviderInstitutionID).subscribe((response: LoginFormResponse) => {

      this.loginFormResponse = response;
      this.formId = response.ProviderFormId;
      this.formFields = response.Fields;
      this.formFields.map((x) => {
        if (x.FieldType === FieldType.PASSWORD) {
          x.InputType = 'password';
        }
      });

      this.createLoginForm();

      this.cd.detectChanges();
      this.cd.markForCheck();
    });
  }

  private createLoginForm(): void {
    const formGroup = {};
    for (const formField of this.formFields) {
      if (formField.FieldType === FieldType.OPTION) {
        formGroup[formField.Name] = [formField.ValidValues[0]?.OptionValue, formField.IsOptional ? null : Validators.required];
      } else {
        formGroup[formField.Name] = [null, formField.IsOptional ? null : Validators.required];
      }
    }
    this.loginForm = this.fb.group(formGroup);
    if (this.formChanged) {
      this.subs.add(
        this.loginForm.valueChanges.subscribe(() => {
          for (const formField of this.formFields) {
            if (!this.loginForm.controls[formField.Name].errors?.required) {
              this.loginForm.controls[formField.Name].setErrors(null);
            }
          }
          this.formChanged.emit(this.loginForm.valid);
        })
      );
    }
    this.formCreated = true;
  }

  togglePassword(field: FinancialField): void {
    if (field.InputType === 'password') {
      field.InputType = 'text';
    } else {
      field.InputType = 'password';
    }
  }

  submit(): void {
    if (this.isUpdatingLoginCredentials) {
      this.updateCredentials();
    } else {
      this.login();
    }
  }

  login(): void {
    if (this.isMFA) {
      const linkFinancialInstitutionMFARequest: LinkFinancialInstitutionMfaRequest = new LinkFinancialInstitutionMfaRequest();
      linkFinancialInstitutionMFARequest.InstitutionAccount = this.institutionAccount;
      linkFinancialInstitutionMFARequest.AutoLinkAccounts = true;
      linkFinancialInstitutionMFARequest.Form = new LoginForm();
      linkFinancialInstitutionMFARequest.Form.Fields = [];
      for (const formField of this.formFields) {
        linkFinancialInstitutionMFARequest.Form.Fields.push({
          FieldId: formField.ProviderFieldId.toString(),
          Name: formField.Name,
          Value: this.loginForm.controls[formField.Name].value,
          isOptional: formField.IsOptional
        });
      }
      this.accountsService.linkFinancialInstitutionMFA(this.sessionService.user.UserID, this.loginFormResponse.FinancialInstitutionId,
        this.institutionAccount.InstitutionAccountId, linkFinancialInstitutionMFARequest)
        .subscribe((result: LinkFinancialInstitutionResponse) => {
          this.loginFinishedSuccessfully = true;
          this.handleSuccessfulLogin(result.FinancialAccounts);
        }, (error) => {
          this.handleFailedLogin(error);
        });
    } else {
      const linkFinancialInstitutionRequest: LinkFinancialInstitutionRequest = new LinkFinancialInstitutionRequest();
      linkFinancialInstitutionRequest.institution = this.institution;
      linkFinancialInstitutionRequest.loginForm = new LoginForm();
      linkFinancialInstitutionRequest.loginForm.FormId = this.formId;
      linkFinancialInstitutionRequest.loginForm.Fields = [];
      for (const formField of this.formFields) {
        linkFinancialInstitutionRequest.loginForm.Fields.push({
          FieldId: formField.ProviderFieldId.toString(),
          Name: formField.Name,
          Value: this.loginForm.controls[formField.Name].value,
          isOptional: formField.IsOptional
        });
      }
      this.accountsService.linkFinancialInstitution(this.sessionService.user.UserID, this.loginFormResponse.FinancialInstitutionId,
        linkFinancialInstitutionRequest).subscribe((result: LinkFinancialInstitutionResponse) => {
        if (result.Form) {
          this.isMFA = true;
          this.institutionAccount = result.InstitutionAccount;
          this.formFields = result.Form.Fields;
          const formGroup = {};
          for (const formField of result.Form.Fields) {
            formGroup[formField.Name] = ['', Validators.required];
          }
          this.loginForm = this.fb.group(formGroup);
        } else {
          this.loginFinishedSuccessfully = true;
          this.handleSuccessfulLogin(result.FinancialAccounts);
        }
      }, (error) => {
        this.handleFailedLogin(error);
      });
    }
  }

  updateCredentials(): void {
    if (this.isMFA) {
      const linkFinancialInstitutionMFARequest: LinkFinancialInstitutionMfaRequest = new LinkFinancialInstitutionMfaRequest();
      linkFinancialInstitutionMFARequest.InstitutionAccount = this.institutionAccount;
      linkFinancialInstitutionMFARequest.AutoLinkAccounts = true;
      linkFinancialInstitutionMFARequest.Form = new LoginForm();
      linkFinancialInstitutionMFARequest.Form.Fields = [];
      for (const formField of this.formFields) {
        linkFinancialInstitutionMFARequest.Form.Fields.push({
          FieldId: formField.ProviderFieldId.toString(),
          Name: formField.Name,
          Value: this.loginForm.controls[formField.Name].value,
          isOptional: formField.IsOptional
        });
      }
      this.accountsService.linkFinancialInstitutionMFA(this.sessionService.user.UserID, this.loginFormResponse.FinancialInstitutionId,
        this.institutionAccount.InstitutionAccountId, linkFinancialInstitutionMFARequest)
        .subscribe((result: LinkFinancialInstitutionResponse) => {
          this.loginFinishedSuccessfully = true;
          this.handleSuccessfulUpdateCredentials(result.FinancialAccounts);
        }, (error) => {
          this.handleFailedLogin(error);
        });
    } else {
      const loginForm = new LoginForm();
      loginForm.FormId = this.formId;
      loginForm.Fields = [];
      for (const formField of this.formFields) {
        loginForm.Fields.push({
          FieldId: formField.ProviderFieldId.toString(),
          Name: formField.Name,
          Value: this.loginForm.controls[formField.Name].value,
          isOptional: formField.IsOptional
        });
      }
      this.accountsService.updateLogInCredentials(this.sessionService.user.UserID, this.account.FinancialAccountID, loginForm)
        .subscribe((result: LinkFinancialInstitutionResponse) => {
        if (result.Form) {
          this.isMFA = true;
          this.institutionAccount = result.InstitutionAccount;
          this.formFields = result.Form.Fields;
          const formGroup = {};
          for (const formField of result.Form.Fields) {
            formGroup[formField.Name] = ['', Validators.required];
          }
          this.loginForm = this.fb.group(formGroup);
        } else {
          this.loginFinishedSuccessfully = true;
          this.handleSuccessfulUpdateCredentials(result.FinancialAccounts);
        }
      }, (error) => {
        this.handleFailedLogin(error);
      });
    }
  }

  private handleSuccessfulLogin(accounts: FinancialAccount[]): void {
    const newAccounts = accounts.filter(x => {
      return this.linkedAccounts.find((z) => z.Number === x.Number && z.Name === x.Name &&
        x.FinancialInstitutionID === z.FinancialInstitution.FinancialInstitutionID) === undefined;
    });
    if (newAccounts.length > 0) {
      this.accountsService.getAccounts().subscribe((result: FinancialAccount[]) => {
        this.store.dispatch(LinkFinancialInstitution({payload: result.filter(x => x.AssetCategory !== 2 && !x.ManualAccount)}));
        this.loginSuccess.emit(accounts);
      });
    } else {
      this.institutionAlreadyLinked = true;
      this.loginSuccessWithNoAccounts.emit();
    }
  }

  private handleFailedLogin(error: HttpErrorResponse): void {
    try {
      let errorMessage = '';
      for (const errorString in error.error.Errors) {
        if (error.error.Errors.hasOwnProperty(errorString)) {
          errorMessage = `${errorMessage} ${error.error.Errors[errorString].join(' ')}`;
        }
      }
      this.messageService.add({severity: 'error', summary: 'Error', detail: errorMessage});
    } catch {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Invalid credentials'});
    }
    for (const formField of this.formFields) {
      this.loginForm.controls[formField.Name].setErrors({invalid: true});
    }
    this.loginFailed.emit();
  }

  private handleSuccessfulUpdateCredentials(accounts: FinancialAccount[]): void {
    this.accountsService.getAccounts().subscribe((result: FinancialAccount[]) => {
      this.store.dispatch(LinkFinancialInstitution({payload: result.filter(x => x.AssetCategory !== 2 && !x.ManualAccount)}));
      this.updateCredentialsSuccess.emit(accounts);
    });
  }

  resetClick(): void {
    this.formCreated = false;
    this.isMFA = false;
    this.institutionAlreadyLinked = false;
    this.loginFinishedSuccessfully = false;
    this.resetClicked.emit();
    this.getLoginForm();
  }
}
