import { Pipe, PipeTransform } from '@angular/core';
import { Institution } from '@models/institution';
import { environment } from '../../../../environments/environment';

@Pipe({
  name: 'logoUrl'
})
export class LogoUrlPipe implements PipeTransform {

  transform(institution: Institution): string {
    let logoUrl = environment.INSTITUTION_LOGO_BASE_URL + institution.LogoID + '.jpg';
    if (institution.LogoUrl && institution.LogoUrl !== '') {
      logoUrl = institution.LogoUrl;
    }
    return logoUrl;
  }

}
