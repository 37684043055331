import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '@core/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private session: SessionService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const msUserId = route.queryParams.msUserId;
    this.session.setParamData(route.queryParams)
    if (msUserId) {
      this.session.setMoneysoftUserId(msUserId);
    }

    if (this.session.user !== null && this.session.user !== undefined) {
      return true;
    }

    return this.session.authenticateUser(state.url);
  }
}
