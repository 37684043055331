import { Pipe, PipeTransform } from '@angular/core';
import { AssetCategory } from '@models/enums';

@Pipe({
  name: 'assetCategoryName'
})
export class AssetCategoryNamePipe implements PipeTransform {

  transform(value: number, plural: boolean): string {
    switch (value) {
      case AssetCategory.BANK: {
        return plural ? 'Banks' : 'Bank';
      }
      case AssetCategory.PROPERTY: {
        return plural ? 'Properties' : 'Property';
      }
      case AssetCategory.VEHICLE: {
        return plural ? 'Vehicles' : 'Vehicle';
      }
      case AssetCategory.INVESTMENT: {
        return plural ? 'Investments' : 'Investment';
      }
      case AssetCategory.CREDIT_CARD: {
        return plural ? 'Credit cards' : 'Credit card';
      }
      case AssetCategory.LOAN: {
        return plural ? 'Loans' : 'Loan';
      }
      case AssetCategory.OTHER_ASSET: {
        return plural ? 'Other assets' : 'Other asset';
      }
      case AssetCategory.LOYALTY: {
        return plural ? 'Loyalties' : 'Loyalty';
      }
      case AssetCategory.OTHER_LIABILITY: {
        return plural ? 'Other liabilities' : 'Other liability';
      }
      case AssetCategory.INSURANCE: {
        return plural ? 'Insurances' : 'Insurance';
      }
      case AssetCategory.REWARD: {
        return plural ? 'Rewards' : 'Reward';
      }
      case AssetCategory.BILL: {
        return plural ? 'Bills' : 'Bill';
      }
    }
  }

}
