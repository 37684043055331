import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[appDecimalInput]'
})
export class DecimalInputDirective implements AfterViewInit {

  @Input('appDecimalInput')
  set decimalPoints(decimalPoints: number) {
    switch (decimalPoints) {
      case 0: {
        this.regex = new RegExp(/^\d+$/);
        this.digitsInfo = '1.0-0';
        break;
      }
      case 1: {
        this.regex = new RegExp(/^\d*\.?\d{0,1}$/g);
        this.digitsInfo = '1.1-1';
        break;
      }
      case 2: {
        this.regex = new RegExp(/^\d*\.?\d{0,2}$/g);
        this.digitsInfo = '1.2-2';
        break;
      }
      case 3: {
        this.regex = new RegExp(/^\d*\.?\d{0,3}$/g);
        this.digitsInfo = '1.3-3';
        break;
      }
      case 4: {
        this.regex = new RegExp(/^\d*\.?\d{0,4}$/g);
        this.digitsInfo = '1.4-4';
        break;
      }
      case 5: {
        this.regex = new RegExp(/^\d*\.?\d{0,5}$/g);
        this.digitsInfo = '1.5-5';
        break;
      }
      case null: {
        this.regex = new RegExp(/^\d*\.?\d{0,10}$/g);
        this.digitsInfo = null;
        break;
      }
      default: {
        this.regex = new RegExp(/^\d*\.?\d{0,10}$/g);
        this.digitsInfo = '1.10-10';
        break;
      }
    }
  }

  @Input() decimalInputMin = 0;
  @Input() decimalInputMax = 100;
  @Output() updateValue: EventEmitter<any> = new EventEmitter();

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private digitsInfo = '1.2-2';

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    /*setTimeout(() => {
      this.el.nativeElement.value = new DecimalPipe('en_US').transform(this.el.nativeElement.value, this.digitsInfo);
    }, 500);*/
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const selectionStart = this.el.nativeElement.selectionStart;
    const selectionEnd = this.el.nativeElement.selectionEnd;
    const next: string = [current.slice(0, selectionStart), event.key === 'Decimal' ? '.' : event.key, current.slice(selectionEnd)].join('');
    if ((next && !String(next).match(this.regex))) {
      event.preventDefault();
    } else if (next && +next > this.decimalInputMax) {
      event.preventDefault();
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (Number(this.el.nativeElement.value) < this.decimalInputMin) {
      /*if (this.digitsInfo !== null && this.digitsInfo !== undefined) {
        this.el.nativeElement.value = new DecimalPipe('en_US').transform(this.decimalInputMin.toString(), this.digitsInfo);
      } else {
        this.el.nativeElement.value = this.decimalInputMin;
      }*/
      this.el.nativeElement.value = this.decimalInputMin;
      this.updateValue.emit(this.el.nativeElement.value);
    }/* else {
      if (this.digitsInfo !== null && this.digitsInfo !== undefined) {
        this.el.nativeElement.value = new DecimalPipe('en_US').transform(this.el.nativeElement.value, this.digitsInfo);
      }
    }*/
  }

}
