<p-dialog [style]="{width: '1060px'}" [baseZIndex]="10006" [visible]="true" [draggable]="false" [modal]="true" [closable]="false" [showHeader]="true"
          [contentStyle]="{'overflow':'visible'}">
  <ng-template pTemplate="header">
    <h2 #focusable class="dialog-title" tabindex="0">Link Account</h2>
  </ng-template>

  <div class="wrapper" *ngIf="selectedInstitution === null">
    <div>
      <div class="content-header">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input #input type="text" pInputText class="search-input" placeholder="Find institution" [(ngModel)]="searchParam" />
        </span>

        <div class="form-group">
          <label>Sort by</label>
          <p-dropdown [options]="sortOptions" optionLabel="label" optionValue="value" [(ngModel)]="sortBy" (ngModelChange)="filterChanged()"></p-dropdown>
        </div>
      </div>

      <div class="content-institutions">
        <div class="institution-item" [ngClass]="{'no-padding': (institutionIndex + 1) % 3 === 0}" *ngFor="let institution of visibleFilteredInstitutions; let institutionIndex = index" (click)="selectInstitution(institution)">
          <img [src]="institution | logoUrl" height="60px" alt="Logo">
          <h5>{{institution.Name}}</h5>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="buttons-wrapper">
        <button class="btn btn-link" type="button" [disabled]="pageIndex === 0" (click)="prev()">Previous</button>
        <div class="divider"></div>
        <button class="btn btn-link" type="button" [disabled]="(pageIndex + 1) * 9 >= filteredInstitutions.length" (click)="next()">Next</button>
      </div>
    </div>
  </div>

  <div class="step-2-wrapper" *ngIf="selectedInstitution !== null">
    <app-institution-login #loginInstitution [institution]="selectedInstitution"
                           (formChanged)="loginFormChanged($event)"
                           (loginSuccess)="onLoginSuccess()" (loginSuccessWithNoAccounts)="onLoginSuccess()" (resetClicked)="resetLogin()"></app-institution-login>
  </div>

  <ng-template pTemplate="footer">
    <p-button type="button" (click)="closeConfirm($event)" label="Cancel" styleClass="p-button-text" *ngIf="!institutionLoginSuccess"></p-button>
    <button pButton type="button" label="Link Accounts" (click)="link()" [disabled]="!loginFormValid" *ngIf="selectedInstitution && !institutionLoginSuccess"></button>
    <button pButton type="button" label="Close" (click)="close()" *ngIf="institutionLoginSuccess"></button>
  </ng-template>
</p-dialog>
<p-confirmPopup [baseZIndex]="10007"></p-confirmPopup>
