<div class="new-wrapper">
  <button type="button" class="btn btn-solid" (click)="linkAccount()" *ngIf="router.url.split('?')[0] === '/accounts'">
    <span class="pi pi-plus"></span>
    <span>Link Account</span>
  </button>
  <button type="button" class="btn btn-solid" (click)="addProperty()" *ngIf="router.url.split('?')[0] === '/properties'">
    <span class="pi pi-plus"></span>
    <span>Add Property</span>
  </button>
  <button type="button" class="btn btn-solid" (click)="addOther()" *ngIf="router.url.split('?')[0] === '/other-accounts'">
    <span class="pi pi-plus"></span>
    <span>Add Other</span>
  </button>
</div>

<ul>
  <li>
    <a routerLink="/accounts" routerLinkActive="active-link">
      <img width="32" height="19" src="assets/images/ic-link.svg" alt="Accounts" />
      <span>Linked accounts</span>
      <span class="badge">{{accounts.length}}</span>
    </a>

  </li>
  <ng-container *ngIf="router.url.split('?')[0] === '/accounts'">
    <li class="sub-menu" *ngFor="let subMenu of accountTypes">
      <a routerLink="/accounts" [queryParams]="{ accountType: subMenu.label}" routerLinkActive="active-link">
        <span>{{subMenu.label | accountTypeName}}</span>
        <span class="badge light">{{subMenu.accounts.length}}</span>
      </a>
    </li>
  </ng-container>
  <li>
    <a routerLink="/properties" routerLinkActive="active-link">
      <img width="32" height="26" src="assets/images/ic-property.svg" alt="Properties" />
      <span>Properties</span>
      <span class="badge">{{(properties$ | async).length}}</span>
    </a>
  </li>
  <li>
    <a routerLink="/other-accounts" routerLinkActive="active-link">
      <img width="32" height="30" src="assets/images/ic-other.svg" alt="Other accounts" />
      <span>Other</span>
      <span class="badge">{{(manualAccounts$ | async).length}}</span>
    </a>
  </li>
</ul>
