import { Action, createReducer, on } from '@ngrx/store';
import { Migration, Calculation, LoadNetworth, calculateTotalNetworth, calculateTotalAsset, calculateTotalLiability, calculateTotalUnAllocated, setUserData } from '../actions/data-feed.actions';

export const stateKey = 'dataFeedAccountsState';

export interface State {
    isMigrated: Boolean;
    contentHeight: number;
    netWorthData: any;
    totalNetworth: number;
    totalAssets: number;
    totalLiabilities: number;
    totalUnAllocated: number;
    userData: any;
}

export const initialState: State = {
    isMigrated: false,
    contentHeight: 0,
    netWorthData: [],
    totalNetworth: 0,
    totalAssets: 0,
    totalLiabilities: 0,
    totalUnAllocated: 0,
    userData: {}
};

const dataFeedReducer = createReducer(
  initialState,
  on(Migration, (state, { payload }) => ({
    ...state,
    isMigrated: payload
  })),
  on(Calculation, (state, { payload }) => ({
    ...state,
    contentHeight: payload
  })),
  on(LoadNetworth, (state, { payload }) => ({
    ...state,
    netWorthData: payload
  })),
  on(calculateTotalNetworth, (state, { payload }) => ({
    ...state,
    totalNetworth: payload
  })),
  on(calculateTotalAsset, (state, { payload }) => ({
    ...state,
    totalAssets: payload
  })),
  on(calculateTotalLiability, (state, { payload }) => ({
    ...state,
    totalLiabilities: payload
  })),
  on(calculateTotalUnAllocated, (state, { payload }) => ({
    ...state,
    totalUnAllocated: payload
  })),
  on(setUserData, (state, { payload }) => ({
    ...state,
    userData: payload
  })),
);

export function reducer(state: State | undefined, action: Action): State {
  return dataFeedReducer(state, action);
}
