import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoUrlPipe } from './pipes/logo-url.pipe';
import { AssetCategoryNamePipe } from './pipes/asset-category-name.pipe';
import { AssetCategoryImagePipe } from './pipes/asset-category-image.pipe';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { AccountTypeNamePipe } from './pipes/account-type-name.pipe';
import { TwoDecimalPipe } from './pipes/two-decimal.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LogoUrlPipe,
    AssetCategoryNamePipe,
    AssetCategoryImagePipe,
    DecimalInputDirective,
    AccountTypeNamePipe,
    TwoDecimalPipe
  ],
  exports: [
    LogoUrlPipe,
    AssetCategoryNamePipe,
    AssetCategoryImagePipe,
    DecimalInputDirective,
    AccountTypeNamePipe,
    TwoDecimalPipe
  ]
})
export class SharedModule { }
