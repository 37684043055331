import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MyfDialogService {
  private componentRef: ComponentRef<any>;
  private resolve: (result?: any) => void;
  private reject: (reason?: any) => void;
  private result: Promise<any>;
  dialogRef: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              @Inject(DOCUMENT) private doc: any) { }

  openModal(component): any {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.doc.body.appendChild(domElem);

    this.result = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });

    return {
      cmpInstance: this.componentRef.instance,
      destroy: () => {
        return this.componentRef.destroy();
      },
      result: this.result,
      componentRef: this.componentRef
    };
  }

  closeModal(data: any): void {
    this.resolve(data);
    this.destroyModalRef();
  }

  dismissModal(reason?): void {
    if (reason) {
      this.reject(reason);
    }
    this.destroyModalRef();
  }

  private destroyModalRef(): void {
    this.appRef.detachView(this.componentRef.hostView);
    setTimeout(() => {
      this.componentRef.destroy();
    }, 50);
  }

  public destroyModalRefExternal(componentRef): void {
    this.appRef.detachView(componentRef.hostView);
    setTimeout(() => {
      componentRef.destroy();
    });
  }
}
