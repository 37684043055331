import { Action, createReducer, on } from '@ngrx/store';
import { User } from '@models/user';
import { LoadUser } from '../actions/user.actions';

export const stateKey = 'userState';

export interface State {
  user: User;
}
export const initialState: State = {
  user: null
};

const userReducer = createReducer(
  initialState,
  on(LoadUser, (state, { payload }) => ({
    user: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return userReducer(state, action);
}
