import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { distinctUntilChanged } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';
import { LoaderConfig } from '@models/loader-config';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {
  title = 'Loading...';
  display = false;
  blockUI = false;
  value = 0;
  private interval: any;
  subs: Subscription = new Subscription();

  constructor(private loaderService: LoaderService,
              private cd: ChangeDetectorRef,
              private location: Location) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.location.subscribe((x) => {
        if (x.type === 'popstate') {
          this.loaderService.hide();
        }
      })
    );

    this.loaderService.subject.pipe(
      distinctUntilChanged()
    ).subscribe((loaderConfig: LoaderConfig) => {
      if (loaderConfig) {
        clearInterval(this.interval);
        this.title = loaderConfig.title;
        this.blockUI = loaderConfig.blockUI || false;
        this.value = 0;
        this.show();

        this.interval = setInterval(() => {
          this.value += Math.floor(9900 / 2000);
          if (this.value > 100) {
            this.value = 0;
          }
          this.cd.markForCheck();
        }, 100);
      } else {
        clearInterval(this.interval);
        this.hide();
      }
    });
  }

  public show(): void {
    this.display = true;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  public hide(): void {
    this.display = false;
    this.blockUI = false;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
