import { Action, createReducer, on } from '@ngrx/store';
import { AddProperty, DeleteProperty, EditProperty, LoadProperties } from '../actions/property.actions';
import { Property } from '@models/property';

export const stateKey = 'propertiesState';

export interface State {
  properties: Property[];
}
export const initialState: State = {
  properties: []
};

const propertiesReducer = createReducer(
  initialState,
  on(LoadProperties, (state, { payload }) => ({
    properties: [...payload]
  })),
  on(AddProperty, (state, { payload }) => ({
    properties: [...state.properties, payload]
  })),
  on(EditProperty, (state, { payload }) => ({
    properties: state.properties.map(item => {
      return item.PropertyID === payload.PropertyID ? Object.assign({}, item, payload) : item;
    })
  })),
  on(DeleteProperty, (state, { payload }) => ({
    properties: state.properties.filter(x => x.PropertyID !== payload.PropertyID)
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return propertiesReducer(state, action);
}
