<p-dialog [(visible)]="display" [draggable]="false" [resizable]="false" [modal]="blockUI" [closeOnEscape]="false"
          [closable]="false" [baseZIndex]="10000001" [focusOnShow]="false" [focusTrap]="false"
          [showHeader]="false" styleClass="app-loader">
  <div class="loader-wrapper">
    <div class="prog">
      <div id="filler" [style.height.%]="value" class="filler"></div>
    </div>
    <span class="loader-label">{{title}}</span>
  </div>
</p-dialog>
