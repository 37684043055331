import { Action, createReducer, on } from '@ngrx/store';
import { PendingMigration } from '@models/migration/pending-migration';
import { CompletePendingMigration, LoadPendingMigrations, RemoveCompletedPendingMigration } from '@store/actions/migration.actions';

export const stateKey = 'migrationState';

export interface State {
  pendingMigrations: PendingMigration[];
  completedMigrations: PendingMigration[];
}
export const initialState: State = {
  pendingMigrations: [],
  completedMigrations: []
};

const pendingMigrationsReducer = createReducer(
  initialState,
  on(LoadPendingMigrations, (state, { payload }) => ({
    pendingMigrations: [...payload],
    completedMigrations: [...state.completedMigrations]
  })),
  on(CompletePendingMigration, (state, { payload }) => ({
    pendingMigrations: state.pendingMigrations.filter(x => x.Institution.FinancialInstitutionID !== payload.Institution.FinancialInstitutionID),
    completedMigrations: [payload]
  })),
  on(RemoveCompletedPendingMigration, (state, { payload }) => ({
    pendingMigrations: state.pendingMigrations,
    completedMigrations: state.completedMigrations.filter(x => x.Institution.FinancialInstitutionID !== payload.Institution.FinancialInstitutionID)
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return pendingMigrationsReducer(state, action);
}
