<p-dialog [style]="{width: '520px'}" [baseZIndex]="10006" [visible]="true" [draggable]="false" [modal]="true" [closable]="false" [showHeader]="true"
          [contentStyle]="{'overflow':'visible'}">
  <ng-template pTemplate="header">
    <h2 #focusable class="dialog-title" tabindex="0">{{isEditMode ? 'Edit' : 'Add'}} Property</h2>
  </ng-template>

  <div class="steps-wrapper">

    <div class="step-counter">
      <div class="step-item" [ngClass]="{'active': step === 1}">Address</div>
      <div class="step-item" [ngClass]="{'active': step === 2}">Details</div>
      <div class="step-item" [ngClass]="{'active': step === 3}">Valuation</div>
      <div class="line"></div>
    </div>

    <form class="form-wrapper" [formGroup]="propertyForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="step step-1" *ngIf="step === 1">
        <div class="form-group" *ngIf="!isEditMode">
          <label for="txtAddress">Street address<span class="required">*</span></label>
          <p-autoComplete inputId="txtAddress" minLength="3" placeholder="Search address" [suggestions]="addresses" field="label"
                          (onSelect)="addressSelected($event)" [disabled]="isEditMode"
                          (completeMethod)="search($event)" [formControlName]="'address'">
            <ng-template let-ppAddress pTemplate="item">
              <div class="item-wrapper">
                <label class="custom-label">{{ppAddress.value.Street}}</label>
                <label class="custom-label">{{ppAddress.value.Suburb}}, {{ppAddress.value.State}}, {{ppAddress.value.ZipCode}}</label>
              </div>
            </ng-template>
          </p-autoComplete>
          <span *ngIf="!isEditMode" class="caption">Start typing the address and the system will automatically present the most relevant results</span>
        </div>
        <div class="form-group" *ngIf="isEditMode">
          <label for="txtStreet">Street Address</label>
          <input id="txtStreet" type="text" [formControlName]="'street'">
        </div>
        <div class="row">
          <div class="col col-left">
            <div class="form-group">
              <label for="cmbState">State*</label>
              <p-dropdown id="cmbState" [options]="countries" [formControlName]="'state'" placeholder="Select state"
                          optionLabel="Abbreviation" optionValue="Abbreviation"></p-dropdown>
            </div>
          </div>
          <div class="col col-right">
            <div class="form-group">
              <label for="txtCity">City/Suburb*</label>
              <input id="txtCity" type="text" [formControlName]="'city'">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="txtNickname">Nickname</label>
          <input id="txtNickname" type="text" [formControlName]="'nickname'">
        </div>
      </div>

      <div class="step step-2" *ngIf="step === 2">
        <div class="form-group">
          <label for="cmbType">Type*</label>
          <p-dropdown id="cmbType" [options]="types" optionLabel="label" optionValue="value" [formControlName]="'type'"></p-dropdown>
        </div>

        <div class="row">
          <div class="col col-left">
            <div class="form-group">
              <label for="txtBedrooms">Bedrooms</label>
              <!--<p-inputNumber inputId="txtBedrooms" [formControlName]="'bedrooms'" min="0" max="50"></p-inputNumber>-->
              <input id="txtBedrooms" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="50" [formControlName]="'bedrooms'" [appDecimalInput]="0" autocomplete="off">
            </div>
          </div>
          <div class="col col-right">
            <div class="form-group">
              <label for="txtBathrooms">Bathrooms</label>
              <!--<p-inputNumber inputId="txtBathrooms" [formControlName]="'bathrooms'" min="0" max="50"></p-inputNumber>-->
              <input id="txtBathrooms" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="50" [formControlName]="'bathrooms'" [appDecimalInput]="0" autocomplete="off">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-left">
            <div class="form-group">
              <label for="txtCars">Cars</label>
              <!--<p-inputNumber inputId="txtCars" [formControlName]="'cars'" min="0" max="50"></p-inputNumber>-->
              <input id="txtCars" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="50" [formControlName]="'cars'" [appDecimalInput]="0" autocomplete="off">
            </div>
          </div>
          <div class="col col-right">
            <div class="form-group">
              <label for="txtLandSize">Land area</label>
              <div class="p-inputgroup right-side">
                <input id="txtLandSize" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="1000000000" [formControlName]="'landSize'" [appDecimalInput]="0" autocomplete="off">
                <span class="p-inputgroup-addon">m<sup>2</sup></span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="step step-3" *ngIf="step === 3">
        <label class="address-label">Address</label>
        <div class="address-info">
          <ng-container *ngIf="selectedAddress">
            <span>{{selectedAddress.Street}}</span><br>
            <span>{{selectedAddress.Suburb}}, {{selectedAddress.State}}, {{selectedAddress.ZipCode}}</span>
          </ng-container>
          <ng-container *ngIf="!selectedAddress">
            <span>{{propertyForm.controls.street?.value}}</span><br>
            <span>{{propertyForm.controls.city?.value}}, {{propertyForm.controls.state?.value}}</span>
          </ng-container>
        </div>

        <div class="form-group">
          <label for="txtValuation">Valuation*</label>
          <div class="p-inputgroup left-side">
            <span class="p-inputgroup-addon">$</span>
            <!--<input id="txtValuation" class="cms-decimal-input" [decimalInputMin]="0" [decimalInputMax]="1000000000" [formControlName]="'valuation'" [appDecimalInput]="0" autocomplete="off">-->
            <input id="txtValuation" currencyMask class="cms-decimal-input" autocomplete="off" [formControlName]="'valuation'" [options]="{ prefix: '', thousands: ',', decimal: '.', precision: 0, align: 'left' }">
          </div>
          <!--<p-inputNumber inputId="txtValuation" [formControlName]="'valuation'" min="0" mode="currency" currency="USD" locale="en-US"></p-inputNumber>-->
        </div>

        <div class="text-center">
          <button *ngIf="selectedAddress && !isEditMode" class="btn btn-solid" type="button" (click)="estimate()">Get estimated value</button>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="footer-buttons">
      <p-button type="button" (click)="closeClick($event)" label="Cancel" styleClass="p-button-text"></p-button>
      <div class="action-buttons">
        <button pButton type="button" label="Back" (click)="prev()" icon="pi pi-angle-left" iconPos="left" class="p-button-outlined" *ngIf="step !== 1"></button>
        <button pButton type="button" label="Next" (click)="next()" icon="pi pi-angle-right" iconPos="right" *ngIf="step !== 3"></button>
        <button pButton type="button" [label]="isEditMode ? 'Save' : 'Add Property'" [disabled]="propertyForm.invalid" (click)="onSubmit()" *ngIf="step === 3"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-confirmPopup [baseZIndex]="10007"></p-confirmPopup>

<p-toast baseZIndex="10007" position="bottom-center"></p-toast>
