import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CreateOtherAccountRequest } from '@models/request/create-other-account-request';
import { Observable } from 'rxjs';
import { FinancialAccount } from '@models/financial-account';
import { LoaderConfig } from '@models/loader-config';
import { environment } from '../../../environments/environment';

@Injectable()
export class OtherAccountsService {

  loaderConfig: LoaderConfig = {
    title: 'Loading...',
    delay: 0,
    blockUI: true
  };

  constructor(private http: HttpClient) {
  }

  /**
   * Create new manual account
   * @param userId - active user id
   * @param payload - body
   */
  createOtherAccount(userId: string, payload: CreateOtherAccountRequest[]): Observable<FinancialAccount[]> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/manual`;

    return this.http.post<FinancialAccount[]>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Create new manual account
   * @param userId - active user id
   * @param payload - body
   */
  updateOtherAccount(userId: string, payload: FinancialAccount[]): Observable<FinancialAccount[]> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/details`;

    return this.http.patch<FinancialAccount[]>(url, payload, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Unlink account
   * @param accountId - Id of account to unlink
   */
  unlinkAccount(accountId: number): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/2.0/FinancialAccount/${accountId}`;

    return this.http.delete(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }
}
