import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Country } from '@models/country';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromAppResources from '@store/reducers/app-resources.reducer';
import * as fromProperties from '@store/reducers/property.reducer';
import { selectCountries, selectValuationProviders } from '@store/selectors/app-resources.selector';
import { OptionItem } from '@models/option-item';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertyAddress } from '@models/property-address';
import { PropertiesService } from '../properties.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValuationProvider } from '@models/valuation-provider';
import { CreateProperty } from '@models/property/create-property';
import { Property } from '@models/property';
import { AddProperty, EditProperty } from '@store/actions/property.actions';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
  providers: [
    PropertiesService,
    ConfirmationService,
    MessageService
  ]
})
export class AddPropertyComponent implements OnInit {
  @Output() propertySaved = new EventEmitter();
  @Output() closed = new EventEmitter();

  subs: Subscription = new Subscription();

  step = 1;

  propertyForm: FormGroup;

  addresses: OptionItem[] = [];
  countries: Country[] = [];
  types: OptionItem[] = [
    {label: 'House', value: 1},
    {label: 'Semi', value: 2},
    {label: 'Unit', value: 3},
    {label: 'Villa', value: 4}
  ];
  selectedAddress: PropertyAddress;
  defaultProvider: ValuationProvider;
  estimatedAddress: PropertyAddress;

  editedProperty: Property;
  isEditMode = false;

  constructor(private appResourcesStore: Store<fromAppResources.State>,
              private propertiesStore: Store<fromProperties.State>,
              private propertiesService: PropertiesService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.subs.add(
      this.appResourcesStore.pipe(select(selectCountries)).subscribe((countries: Country[]) => {
        this.countries = countries;
      })
    );

    this.subs.add(
      this.appResourcesStore.pipe(select(selectValuationProviders)).subscribe((providers: ValuationProvider[]) => {
        this.defaultProvider = providers.find(x => x.IsDefault);
      })
    );
  }

  setProperty(property: Property): void {
    this.editedProperty = property;
    this.isEditMode = true;
  }

  next(): void {
    this.step++;
  }

  prev(): void {
    this.step--;
  }

  search(event): void {
    this.propertiesService.getPropertyAddresses(this.defaultProvider ? this.defaultProvider.ID : 2,
      event.query, '', '', '').subscribe((addresses: PropertyAddress[]) => {
      this.addresses = addresses.map((x) => {
        return {
          label: x.Street + ', ' + x.Suburb + ', ' + x.State + ', ' + x.ZipCode,
          value: x
        };
      });
    });
  }

  private createForm(): void {
    if (this.isEditMode) {
      this.propertyForm = this.fb.group({
        state: [this.editedProperty.State, Validators.required],
        city: [this.editedProperty.Suburb, Validators.required],
        street: [this.editedProperty.Street, Validators.required],
        nickname: [this.editedProperty.DisplayName],
        type: [this.editedProperty.PropertyType, Validators.required],
        bedrooms: [this.editedProperty.Bedrooms],
        bathrooms: [this.editedProperty.Bathrooms],
        cars: [this.editedProperty.Carparks],
        landSize: [this.editedProperty.Landarea],
        valuation: [this.editedProperty.ReportedValue, Validators.required]
      });

      this.propertyForm.controls.street.disable();
      this.propertyForm.controls.state.disable();
      this.propertyForm.controls.city.disable();
    } else {
      this.propertyForm = this.fb.group({
        address: ['', Validators.required],
        state: [null, Validators.required],
        city: [null, Validators.required],
        nickname: [null],
        type: [1, Validators.required],
        bedrooms: [null],
        bathrooms: [null],
        cars: [null],
        landSize: [null],
        valuation: [null, Validators.required]
      });
    }
  }

  addressSelected(event): void {
    if (event) {
      this.selectedAddress = event.value;
      this.propertyForm.controls.state.patchValue(event.value.State);
      this.propertyForm.controls.city.patchValue(event.value.Suburb);
    }
  }

  estimate(): void {
    this.propertiesService.estimateProperty(this.defaultProvider ? this.defaultProvider.ID : 2,
      this.selectedAddress.ID).subscribe((result: PropertyAddress) => {
        this.estimatedAddress = result;
        this.propertyForm.controls.valuation.patchValue(result.Estimate);
    }, () => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'No estimate could be found for the specified property'});
    });
  }

  onSubmit(): void {
    if (this.propertyForm.invalid) {
      return;
    }

    if (this.isEditMode) {
      const property: Property = Object.assign({}, this.editedProperty);
      property.Street = this.propertyForm.controls.street.value;
      property.State = this.propertyForm.controls.state.value;
      property.Suburb = this.propertyForm.controls.city.value;
      property.DisplayName = this.propertyForm.controls.nickname.value;
      property.PropertyType = this.propertyForm.controls.type.value;
      property.Bedrooms = this.propertyForm.controls.bedrooms.value;
      property.Bathrooms = this.propertyForm.controls.bathrooms.value;
      property.Carparks = this.propertyForm.controls.cars.value;
      property.Landarea = this.propertyForm.controls.landSize.value;
      if (this.estimatedAddress && this.estimatedAddress.Estimate === this.propertyForm.controls.valuation.value) {
        property.UseEstimate = false;
        property.EstimatedValue = this.propertyForm.controls.valuation.value;
        property.ReportedValue = this.propertyForm.controls.valuation.value;
        // property.ReportedValue = 0;
      } else {
        property.UseEstimate = false;
        // property.EstimatedValue = 0;
        property.EstimatedValue = this.propertyForm.controls.valuation.value;
        property.ReportedValue = this.propertyForm.controls.valuation.value;
      }

      this.propertiesService.updateProperty(property).subscribe((result: Property) => {
        this.propertiesStore.dispatch(EditProperty({payload: result}));
        this.propertySaved.emit();
      });

    } else {
      const createProperty: CreateProperty = new CreateProperty();
      createProperty.PropertyID = -1;
      createProperty.ProviderID = -1;
      createProperty.DisplayName = this.propertyForm.controls.nickname.value;

      if (typeof this.propertyForm.controls.address.value === 'string') {
        createProperty.Address = `${this.propertyForm.controls.address.value}, ${this.propertyForm.controls.city.value}, ${this.propertyForm.controls.state.value}`;
        createProperty.Street = this.propertyForm.controls.address.value;
        createProperty.Code = null;
        createProperty.Usage = 0;
      } else {
        createProperty.Address = this.propertyForm.controls.address.value.label;
        createProperty.Street = this.propertyForm.controls.address.value.value.Street;
        createProperty.Code = this.propertyForm.controls.address.value.value.ZipCode;
        createProperty.ProviderPropertyID = this.propertyForm.controls.address.value.value.ID;
        createProperty.Usage = 0;
      }
      createProperty.Suburb = this.propertyForm.controls.city.value;
      createProperty.State = this.propertyForm.controls.state.value;

      createProperty.PropertyType = this.propertyForm.controls.type.value;
      createProperty.Bathrooms = this.propertyForm.controls.bathrooms.value;
      createProperty.Bedrooms = this.propertyForm.controls.bedrooms.value;
      createProperty.Carparks = this.propertyForm.controls.cars.value;
      createProperty.Landarea = this.propertyForm.controls.landSize.value;

      if (this.estimatedAddress && this.estimatedAddress.Estimate === this.propertyForm.controls.valuation.value) {
        createProperty.UseEstimate = false;
        createProperty.EstimatedValue = this.propertyForm.controls.valuation.value;
        // createProperty.ReportedValue = 0;
        createProperty.ReportedValue = this.propertyForm.controls.valuation.value;
      } else {
        createProperty.UseEstimate = false;
        // createProperty.EstimatedValue = 0;
        createProperty.EstimatedValue = this.propertyForm.controls.valuation.value;
        createProperty.ReportedValue = this.propertyForm.controls.valuation.value;
      }

      this.propertiesService.createProperty(createProperty).subscribe((result) => {
        this.propertiesStore.dispatch(AddProperty({payload: result}));
        this.propertySaved.emit();
      });

    }
  }

  closeClick(event): void {
    if (this.propertyForm.dirty) {
      this.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure? Your changes will be lost.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.closed.emit();
        },
        reject: () => {
          // reject action
        }
      });
    } else {
      this.closed.emit();
    }
  }

}
