export enum AccountType {
  Unknown,
  Bank,
  CreditCard,
  Loan,
  Investment,
  Mortgage,
  Asset,
  Liability,
  Bills,
  Insurance,
  Loyalty,
  Property
}

export enum AccountStatus {
  PENDING = 1,
  SUBMITTED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PARTIAL_COMPLETE,
  COMPLETE,
  TIMEOUT,
  CANCELLED,
  COMPLETE_UNCERTAIN,
  IN_PROGRESS,
  PENDING_DELETE,
  DELETED,
  PROVIDER_ERROR,
  ACCOUNT_LOCKED,
  ADDITIONAL_AUTHENTICATION_REQUIRED,
  DATA_NOT_AVAILABLE,
  SITE_NOT_SUPPORTED,
  USER_ACTION_NEEDED_AT_SITE,
  ACCOUNT_INACTIVE,
  OUT_OF_SYNC,
  MFA_CANNOT_REFRESH,
  PASSWORD_EXPIRED,
  INVALID_PROVIDER_CREDENTIALS = 24,
  MONEYSOFT_ERROR,
  CLOSED
}

export enum PropertyType {
  UNKNOWN,
  HOUSE,
  SEMI,
  UNIT,
  VILLA
}

export enum FieldType {
  UNKNOWN,
  TEXT,
  PASSWORD,
  IMAGE,
  OPTION,
  CHECKBOX,
  RADIO
}

export enum AssetCategory {
  NONE,
  BANK,
  PROPERTY,
  VEHICLE,
  INVESTMENT,
  CREDIT_CARD,
  LOAN,
  OTHER_ASSET,
  LOYALTY,
  OTHER_LIABILITY,
  INSURANCE,
  REWARD,
  BILL
}

export enum AssetType {
  UNSPECIFIED,
  ASSET,
  LIABILITY
}
