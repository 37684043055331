import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FinancialAccount } from '@models/financial-account';
import { environment } from '../../../../environments/environment';
import { Property } from '@models/property';
import { LoaderConfig } from '@models/loader-config';
import { PendingMigration } from '@models/migration/pending-migration';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  loaderConfig: LoaderConfig = {
    title: 'Loading...',
    delay: 0,
    blockUI: true
  };
  constructor(private http: HttpClient) { }

  getAccounts(): Observable<FinancialAccount[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/FinancialAccount/accounts`;

    return this.http.get<FinancialAccount[]>(url);
  }

  getProperties(): Observable<Property[]> {
    const url = `${environment.API_BASE_URL}/api/2.0/Property?excludeNonActive=true`;

    return this.http.get<Property[]>(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  /**
   * Get the List of Institutions which still need Migrating
   * @param userId - The Moneysoft ID of the user currently logged in.
   */
  getPendingMigrations(userId: string): Observable<PendingMigration[]> {
    const url = `${environment.API_BASE_URL}/api/3.0/user/${userId}/account/migrate/pending`;

    return this.http.get<PendingMigration[]>(url);
  }

  getUser(showLoader = false): Observable<any> {
    const url = `${environment.API_BASE_URL}/api/2.0/user`;

    if (showLoader) {
      return this.http.get(url, {
        params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
      });
    } else {
      return this.http.get<any[]>(url);
    }
  }

  impersonate(moneysoftUserId: string): Observable<any> {
    const url = `${environment.API_FORTRESS_CLIENT_BASE_URL}/api/Client/MsImpersonate?userToImpersonateId=${moneysoftUserId}`;

    return this.http.get(url, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  saveMoneysoftId(clientId: string, moneysoftId: string): Observable<any> {
    const url = `${environment.API_CLIENT_AUTH}/api/User/UpdateMoneysoftId?id=${clientId}&moneysoftId=${moneysoftId}`;

    return this.http.patch(url, null, {
      params: new HttpParams().set('loaderConfig', JSON.stringify(this.loaderConfig))
    });
  }

  getMigrateStatus(xeppoCode, companyCode) {
    const params = new HttpParams().set('xeppoCode', xeppoCode).set('companyCode', companyCode)
    return this.http.get(environment.API_FORTRESS_CLIENT_BASE_URL + '/api/RealTimeNetworthDataRead/ClientMigrateStatus', { params });
  }

}
