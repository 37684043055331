<app-header *ngIf="(user$ | async) !== null"></app-header>
<div id="main-container" *ngIf="!isMigrated" class="main-container">
  <aside *ngIf="(user$ | async) !== null">
    <app-sidebar></app-sidebar>
  </aside>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>
<div *ngIf="isMigrated">
    <router-outlet></router-outlet>
</div>
<app-loader></app-loader>
<p-toast position="top-center" key="global500" [baseZIndex]="10007"></p-toast>
<ngx-ui-loader></ngx-ui-loader>
