import { AfterViewInit, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromUser from '@store/reducers/user.reducer';
import { selectUser } from '@store/selectors/user.selector';
import { Observable } from 'rxjs';
import { User } from '@models/user';
import { environment } from '../environments/environment';
import { SessionService } from '@core/services/session.service';
import { CoreService } from '@core/services/core.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  user$: Observable<User>;

  isMigrated: Boolean;

  constructor(private store: Store<fromUser.State>,
    private sessionService: SessionService,
    private coreService: CoreService,
    private cookieService: CookieService) {
  }

  ngOnInit(): void {
    let userData = JSON.parse(localStorage.getItem('userData'));
    const companyCode = this.cookieService.get('companyCode');
    this.isMigrated = userData?.isMigrated === 'true';
    this.coreService.getMigrateStatus(userData?.xeppoCode, companyCode).subscribe((res: any)=> {
      Object.assign(userData, {isMigrated: res.response.toString()})
      localStorage.setItem('userData', JSON.stringify(userData));
    })

    if (!this.sessionService.getMyFortressUserToken()) {
      window.location.href = environment.STAFF_PORTAL_URL;
    }
  }

  ngAfterViewInit(): void {
    this.user$ = this.store.pipe(select(selectUser));
  }
}
