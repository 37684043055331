import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OptionItem } from '@models/option-item';
import { ConfirmationService } from 'primeng/api';
import { OtherAccountsService } from '../other-accounts.service';
import { CreateOtherAccountRequest } from '@models/request/create-other-account-request';
import { FinancialAccount } from '@models/financial-account';
import { Store } from '@ngrx/store';
import * as fromManualAccounts from '@store/reducers/manual-account.reducer';
import { AddManualAccount, UpdateManualAccount } from '@store/actions/manual-account.actions';
import { SessionService } from '@core/services/session.service';

@Component({
  selector: 'app-add-other-account',
  templateUrl: './add-other-account.component.html',
  styleUrls: ['./add-other-account.component.scss'],
  providers: [OtherAccountsService, ConfirmationService]
})
export class AddOtherAccountComponent implements OnInit {
  @Output() accountSaved = new EventEmitter();
  @Output() closed = new EventEmitter();

  selectedAccount: FinancialAccount = null;
  otherAccountForm: FormGroup;
  isEditMode = false;

  assetCategories: OptionItem[] = [
    {label: 'Bank', value: 1},
    {label: 'Property', value: 2},
    {label: 'Vehicle', value: 3},
    {label: 'Investment', value: 4},
    {label: 'Credit card', value: 5},
    {label: 'Loan', value: 6},
    {label: 'Other asset', value: 7},
    {label: 'Loyalty', value: 8},
    {label: 'Other liability', value: 9},
    {label: 'Insurance', value: 10},
    {label: 'Reward', value: 11},
    {label: 'Bill', value: 12},
  ];

  subs: Subscription = new Subscription();

  constructor(private otherAccountsService: OtherAccountsService,
              private confirmationService: ConfirmationService,
              private sessionService: SessionService,
              private store: Store<fromManualAccounts.State>,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  setAccount(account: FinancialAccount): void {
    this.selectedAccount = account;
    this.isEditMode = true;
  }

  onSubmit(): void {
    if (!this.isEditMode) {
      const payload: CreateOtherAccountRequest = new CreateOtherAccountRequest();
      payload.Name = this.otherAccountForm.controls.name.value;
      payload.AssetCategory = this.otherAccountForm.controls.type.value;
      payload.CurrentBalance = this.otherAccountForm.controls.balance.value;
      payload.Currency = 'USD';

      this.otherAccountsService.createOtherAccount(this.sessionService.user.UserID,
        [payload]).subscribe((result: FinancialAccount[]) => {
        this.store.dispatch(AddManualAccount({payload: result[0]}));
        this.accountSaved.emit();
      });
    } else {
      const updatedAccount = JSON.parse(JSON.stringify(this.selectedAccount));
      updatedAccount.Name = this.otherAccountForm.controls.name.value;
      updatedAccount.AssetCategory = this.otherAccountForm.controls.type.value;
      updatedAccount.CurrentBalance = this.otherAccountForm.controls.balance.value;

      this.otherAccountsService.updateOtherAccount(this.sessionService.user.UserID,
        [updatedAccount]).subscribe((result) => {
        this.store.dispatch(UpdateManualAccount({payload: result[0]}));
        this.accountSaved.emit();
      });
    }
  }

  private createForm(): void {
    this.otherAccountForm = this.fb.group({
      type: [this.selectedAccount ? this.selectedAccount.AssetCategory :  1, Validators.required],
      name: [this.selectedAccount ? this.selectedAccount.Name :  null, Validators.required],
      balance: [this.selectedAccount ? this.selectedAccount.CurrentBalance :  null, Validators.required]
    });
  }

  closeClick(event): void {
    if (this.otherAccountForm.dirty) {
      this.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure? Your changes will be lost.',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.closed.emit();
        },
        reject: () => {
          // reject action
        }
      });
    } else {
      this.closed.emit();
    }
  }
}
